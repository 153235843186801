import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import DateLabel from 'components/DateLabel'
import InclusionIcon from 'components/InclusionIcon'
import Subsection from 'components/Subsection'
import Text from 'components/Text'
import useVideoStats from '../hooks/useVideoStats'
import { getCreditsString } from 'utils/string'
import Tooltip from 'components/Tooltip'
import { useState } from 'react'
import { VideoEdit } from 'features/video'

const HeaderCell = styled(props => (
  <TableCell align="center" sx={{ fontWeight: 800, userSelect: 'none' }} {...props} />
))({})
const BodyCell = styled(props => <TableCell align="center" sx={{ userSelect: 'none' }} {...props} />)({})

export default function VideoStats(props) {
  const videoStats = useVideoStats()
  const [selectedVideo, setSelectedVideo] = useState(null)
  const handleVideoEditSuccess = () => setSelectedVideo(null)

  return (
    <Subsection name="Statistiky videí" {...props}>
      <VideoEdit video={selectedVideo} onSuccess={handleVideoEditSuccess} />
      {videoStats.isError && (
        <Stack direction="row" alignItems="center" color="error.main">
          <CancelIcon sx={{ fontSize: 20, mr: 0.5 }} />
          <Text fontWeight={600}>Chyba! Nepodařilo se načíst statistiky.</Text>
        </Stack>
      )}
      {videoStats.isLoading && <Skeleton variant="rounded" width={700} height={300} sx={{ maxWidth: '100%' }} />}
      {videoStats.isSuccess &&
        (videoStats.data.length === 0 ?
          <Stack direction="row" alignItems="center" color="warning.main">
            <InfoIcon sx={{ fontSize: 20, mr: 0.5 }} />
            <Text>Zatím nebyla nahrána žádná videa.</Text>
          </Stack>
        : <Paper elevation={4} sx={{ width: 'max-content', maxWidth: '100%', mt: 1, overflow: 'auto' }}>
            <TableContainer>
              <Table size="small" sx={{ width: 'max-content' }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'background.table.header' }}>
                    <HeaderCell align="left">Video</HeaderCell>
                    <HeaderCell align="left">Kategorie</HeaderCell>
                    <HeaderCell>Placené</HeaderCell>
                    <HeaderCell>Profit</HeaderCell>
                    <HeaderCell>Zakoupeno</HeaderCell>
                    <HeaderCell>Poslední nákup</HeaderCell>
                    <HeaderCell></HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {videoStats.data.map(video => {
                    let totalPurchases = 0
                    Object.values(video.purchases).forEach(p => (totalPurchases += p))
                    return (
                      <TableRow key={video.id} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <BodyCell align="left">
                          <Text fontWeight={600}>
                            {video.group}: {video.name}
                          </Text>
                        </BodyCell>
                        <BodyCell align="left">{video.category}</BodyCell>
                        <BodyCell sx={{ p: 0 }}>
                          <InclusionIcon included={video.paid} />
                        </BodyCell>
                        <BodyCell align={video.paid ? 'right' : 'center'}>
                          {video.paid ?
                            <Text
                              fontWeight={500}
                              fontFamily="Roboto"
                              color={video.profit ? 'success.main' : 'error.main'}
                            >
                              {video.profit} {getCreditsString(video.profit)}
                            </Text>
                          : '-'}
                        </BodyCell>
                        <BodyCell>
                          {video.paid ?
                            <Stack direction="row" alignItems="center" justifyContent="center">
                              <Text fontWeight={500} mr={0.2} fontFamily="Roboto">
                                {totalPurchases}x
                              </Text>
                              <Tooltip
                                placement="right"
                                arrow
                                text={
                                  <Box>
                                    <Stack direction="row" justifyContent="space-between">
                                      <Text>na den:</Text>
                                      <Text fontFamily="Roboto">{video.purchases.day}x</Text>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between">
                                      <Text>na týden:</Text>
                                      <Text fontFamily="Roboto">{video.purchases.week}x</Text>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between">
                                      <Text mr={0.5}>na měsíc:</Text>
                                      <Text fontFamily="Roboto">{video.purchases.month}x</Text>
                                    </Stack>
                                  </Box>
                                }
                              >
                                <InfoOutlinedIcon sx={{ fontSize: 14 }} />
                              </Tooltip>
                            </Stack>
                          : '-'}
                        </BodyCell>
                        <BodyCell>
                          {video.paid && video.latestPurchase !== 0 ?
                            <DateLabel timestamp={video.latestPurchase} withTime />
                          : '-'}
                        </BodyCell>
                        <BodyCell sx={{ p: 0, pr: 0.5 }}>
                          <Tooltip arrow placement="right" text="Upravit video">
                            <IconButton aria-label="edit" size="small" onClick={() => setSelectedVideo(video)}>
                              <EditIcon sx={{ fontSize: 14 }} />
                            </IconButton>
                          </Tooltip>
                        </BodyCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>)}
    </Subsection>
  )
}
