import { useCallback, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MuiStepper from '@mui/material/Stepper'
import Stack from '@mui/material/Stack'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Offers from './Offers'
import Text from 'components/Text'
import Recap from './Recap'
import Payment from './Payment'
import Confirmation from './Confirmation'
import Tooltip from 'components/Tooltip'
import useURLStep from '../hooks/useURLStep'
import useSessionStorage from 'hooks/useSessionStorage'
import { SELECTED_OFFER_STORAGE_KEY } from '../data/constants'

const steps = ['Zvolte nabídku', 'Souhrn objednávky', 'Platba', 'Vyřízení objednávky']

export default function TopUpFunds() {
  const [offerID, setOfferID] = useSessionStorage(SELECTED_OFFER_STORAGE_KEY, null)
  const [activeStep, setActiveStep] = useURLStep(offerID === null ? 0 : 1)
  const [checked, setChecked] = useState(false)

  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'))

  const handleNext = () => setActiveStep(activeStep + 1)
  const handleReset = useCallback(() => {
    setChecked(false)
    setOfferID(null)
    setActiveStep(0)
  }, [setActiveStep, setOfferID])
  const handleCheckboxChange = event => setChecked(event.target.checked)

  const handleOfferSelection = offerID => {
    setOfferID(offerID)
    handleNext()
  }

  return (
    <Box height={1} py={2}>
      <Text variant="h5">Dobít kredit</Text>
      <Stack height="inherit" justifyContent="space-between" py={3}>
        <MuiStepper activeStep={activeStep} orientation={matches ? 'horizontal' : 'vertical'} sx={{ mb: 2 }}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel sx={{ userSelect: 'none' }}>{label}</StepLabel>
            </Step>
          ))}
        </MuiStepper>

        {activeStep === 0 && <Offers onSelect={handleOfferSelection} />}
        {activeStep === 1 && <Recap onCancel={handleReset} checked={checked} onChange={handleCheckboxChange} />}
        {activeStep === 2 && <Payment onError={handleReset} />}
        {(activeStep === 3 || activeStep === 4) && <Confirmation step={activeStep} />}

        <Stack direction="row" justifyContent={[0, 3, 4].includes(activeStep) ? 'end' : 'space-between'}>
          {activeStep === 1 && (
            <>
              <Button color="inherit" onClick={handleReset}>
                Zpět na výběr nabídky
              </Button>
              <Stack direction="row" spacing={2}>
                <Tooltip text={checked ? null : 'Musíte souhlasit s našimi podmínkami'} arrow placement="top">
                  <span>
                    <Button onClick={handleNext} disabled={!checked}>
                      Zaplatit
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
            </>
          )}
          {(activeStep === 3 || activeStep === 4) && <Button onClick={handleReset}>Znovu nakoupit</Button>}
        </Stack>
      </Stack>
    </Box>
  )
}
