import useCacheData from 'hooks/useCacheData'
import { getPaymentSummary } from 'services/firebase'

async function fetchPaymentSummary() {
  return (await getPaymentSummary())?.data
}

export default function usePaymentSummary() {
  return useCacheData(['payment-summary'], fetchPaymentSummary)
}
