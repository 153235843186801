import { useUser } from 'features/authentication'
import useCacheData from 'hooks/useCacheData'
import { getBalanceHistory } from 'services/firebase'
import { naturalSort } from 'utils/sort'

async function fetchBalanceHistory() {
  const data = await getBalanceHistory()
  return naturalSort(data.data.history, 'timestamp', true)
}

export default function useBalanceHistory() {
  const user = useUser()
  return useCacheData([user.data.id, 'balance-history'], fetchBalanceHistory, {
    enabled: user.isLoggedIn
  })
}
