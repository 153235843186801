import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import InclusionIcon from 'components/InclusionIcon'
import Text from 'components/Text'
import { getCreditsString } from 'utils/string'

export default function AdminDetails({ video, ...props }) {
  return (
    <Stack alignItems="center" {...props}>
      <Divider orientation="horizontal" sx={{ width: '100%', mb: 1 }} />
      <Stack direction="row" alignItems="center" mb={1}>
        <Text color="text.secondary" fontSize={12} fontWeight={500} sx={{ mr: 0.5 }}>
          Placené video:
        </Text>
        <InclusionIcon included={video.paid} />
      </Stack>
      {video.paid && (
        <Stack mb={1} width="fit-content">
          <Box fontSize={12}>
            <Stack direction="row" justifyContent="space-between">
              <Text fontSize="inherit" color="text.secondary" sx={{ mr: 1 }}>
                Cena na den:
              </Text>
              <Text fontSize="inherit" fontWeight={500}>
                {video.price.day}&nbsp;{getCreditsString(video.price.day)}
              </Text>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Text fontSize="inherit" color="text.secondary" sx={{ mr: 1 }}>
                Cena na týden:
              </Text>
              <Text fontSize="inherit" fontWeight={500}>
                {video.price.week}&nbsp;{getCreditsString(video.price.week)}
              </Text>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Text fontSize="inherit" color="text.secondary" sx={{ mr: 1 }}>
                Cena na měsíc:
              </Text>
              <Text fontSize="inherit" fontWeight={500}>
                {video.price.month}&nbsp;{getCreditsString(video.price.month)}
              </Text>
            </Stack>
          </Box>
        </Stack>
      )}
    </Stack>
  )
}
