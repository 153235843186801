import { useState } from 'react'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { EMPTY_GROUP, EMPTY_NAME, ERROR_MESSAGE, VIDEO_ACCESS_PERIOD, VIDEO_CATEGORY } from '../data/constants'
import Notification from 'components/Notification'
import InputFileButton from 'components/InputFileButton'
import { updateVideo, uploadVideo } from '../utils/video'
import PriceInput from './PriceInput'
import useCacheInvalidation from 'hooks/useCacheInvalidation'
import OrderInput from './OrderInput'

export default function UploadForm({ editing = false, video = null, onSuccess }) {
  const [loading, setLoading] = useState(false)
  const [videoFile, setVideoFile] = useState(null)
  const [videoError, setVideoError] = useState(null)
  const [videoProgress, setVideoProgress] = useState(0)
  const [posterFile, setPosterFile] = useState(null)
  const [posterError, setPosterError] = useState(null)
  const [posterProgress, setPosterProgress] = useState(0)
  const [paid, setPaid] = useState(video ? video.paid : true)
  const [name, setName] = useState(video ? video.name : '')
  const [group, setGroup] = useState(video ? video.group : '')
  const [groupOrder, setGroupOrder] = useState(video ? video.groupOrder : 1)
  const [category, setCategory] = useState(video ? video.category : VIDEO_CATEGORY.ELEMENTARY)
  const [price, setPrice] = useState({
    [VIDEO_ACCESS_PERIOD.DAY]: video ? video.price[VIDEO_ACCESS_PERIOD.DAY] : '49',
    [VIDEO_ACCESS_PERIOD.WEEK]: video ? video.price[VIDEO_ACCESS_PERIOD.WEEK] : '179',
    [VIDEO_ACCESS_PERIOD.MONTH]: video ? video.price[VIDEO_ACCESS_PERIOD.MONTH] : '239'
  })
  const [priceError, setPriceError] = useState({
    [VIDEO_ACCESS_PERIOD.DAY]: false,
    [VIDEO_ACCESS_PERIOD.WEEK]: false,
    [VIDEO_ACCESS_PERIOD.MONTH]: false
  })

  const invalidateQuery = useCacheInvalidation()

  const [description, setDescription] = useState(video ? video.description : '')
  const [submitted, setSubmitted] = useState(false)
  const [uploadSubmitted, setUploadSubmitted] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)

  const handleNameChange = event => setName(String(event.target.value))
  const handleGroupChange = event => setGroup(String(event.target.value))
  const handleDescriptionChange = event => setDescription(String(event.target.value))
  const handleOrderChange = order => setGroupOrder(order)

  const handlePricePerDayChange = price => {
    setPrice(current => ({ ...current, [VIDEO_ACCESS_PERIOD.DAY]: price }))
    setPriceError(current => ({ ...current, [VIDEO_ACCESS_PERIOD.DAY]: price === '' }))
  }
  const handlePricePerWeekChange = price => {
    setPrice(current => ({ ...current, [VIDEO_ACCESS_PERIOD.WEEK]: price }))
    setPriceError(current => ({ ...current, [VIDEO_ACCESS_PERIOD.WEEK]: price === '' }))
  }
  const handlePricePerMonthChange = price => {
    setPrice(current => ({ ...current, [VIDEO_ACCESS_PERIOD.MONTH]: price }))
    setPriceError(current => ({ ...current, [VIDEO_ACCESS_PERIOD.MONTH]: price === '' }))
  }
  const handleSubmit = event => {
    event.preventDefault()

    if (!submitted) {
      setSubmitted(true)
    }

    let valid =
      groupOrder !== '' &&
      videoError === null &&
      posterError === null &&
      !priceError[VIDEO_ACCESS_PERIOD.DAY] &&
      !priceError[VIDEO_ACCESS_PERIOD.WEEK] &&
      !priceError[VIDEO_ACCESS_PERIOD.MONTH]
    if (!editing && videoFile === null) {
      setVideoError(ERROR_MESSAGE.NO_VIDEO_SELECTED)
      valid = false
    }
    if (!editing && posterFile === null) {
      setPosterError(ERROR_MESSAGE.NO_POSTER_SELECTED)
      valid = false
    }
    if (paid) {
      if (price[VIDEO_ACCESS_PERIOD.DAY] === '') {
        setPriceError(current => ({ ...current, [VIDEO_ACCESS_PERIOD.DAY]: true }))
        valid = false
      }
      if (price[VIDEO_ACCESS_PERIOD.WEEK] === '') {
        setPriceError(current => ({ ...current, [VIDEO_ACCESS_PERIOD.WEEK]: true }))
        valid = false
      }
      if (price[VIDEO_ACCESS_PERIOD.MONTH] === '') {
        setPriceError(current => ({ ...current, [VIDEO_ACCESS_PERIOD.MONTH]: true }))
        valid = false
      }
    }

    if (valid) {
      const details = {
        group: group === '' ? EMPTY_GROUP : group,
        groupOrder: parseFloat(groupOrder),
        name: name === '' ? EMPTY_NAME : name,
        description,
        paid,
        price: {
          [VIDEO_ACCESS_PERIOD.DAY]: paid ? Number.parseInt(price[VIDEO_ACCESS_PERIOD.DAY]) : 0,
          [VIDEO_ACCESS_PERIOD.WEEK]: paid ? Number.parseInt(price[VIDEO_ACCESS_PERIOD.WEEK]) : 0,
          [VIDEO_ACCESS_PERIOD.MONTH]: paid ? Number.parseInt(price[VIDEO_ACCESS_PERIOD.MONTH]) : 0
        },
        category: category
      }

      setLoading(true)
      setUploadSubmitted(true)
      if (editing) {
        updateVideo(
          video,
          videoFile,
          posterFile,
          details,
          progress => setVideoProgress(progress),
          progress => setPosterProgress(progress)
        )
          .then(() => {
            invalidateQuery('video-stats')
            invalidateQuery('all-videos')
            if (videoFile !== null) {
              invalidateQuery(['video', video.id])
            }
            if (posterFile !== null) {
              invalidateQuery(['poster', video.id])
            }
            onSuccess()
          })
          .catch(e => {
            console.error('Update error:', e)
            setOpenAlert(true)
          })
          .finally(() => setLoading(false))
      } else {
        uploadVideo(
          videoFile,
          posterFile,
          details,
          progress => setVideoProgress(progress),
          progress => setPosterProgress(progress)
        )
          .then(() => {
            invalidateQuery('video-stats')
            invalidateQuery('all-videos')
            onSuccess()
          })
          .catch(e => {
            console.error('Upload error:', e)
            setOpenAlert(true)
          })
          .finally(() => setLoading(false))
      }
    }
  }

  const handleVideoFileChange = newFile => {
    if (newFile === null) {
      if (!editing) {
        setVideoError(ERROR_MESSAGE.NO_VIDEO_SELECTED)
      }
    } else if (newFile.type !== 'video/webm') {
      setVideoError(ERROR_MESSAGE.UNSUPPORTED_VIDEO_TYPE)
    } else {
      setVideoError(null)
    }
    setVideoFile(newFile)
  }

  const handlePosterFileChange = newFile => {
    if (newFile === null) {
      if (!editing) {
        setPosterError(ERROR_MESSAGE.NO_POSTER_SELECTED)
      }
    } else if (newFile.type !== 'image/jpeg') {
      setPosterError(ERROR_MESSAGE.UNSUPPORTED_POSTER_TYPE)
    } else {
      setPosterError(null)
    }
    setPosterFile(newFile)
  }

  const handlePaidChange = () =>
    setPaid(current => {
      if (current) {
        setPriceError(false)
      }
      return !current
    })

  const handleCategoryChange = event => setCategory(event.target.value)

  // video editing
  const difference = !(
    videoFile === null &&
    posterFile === null &&
    video &&
    video.group === group &&
    video.groupOrder === parseFloat(groupOrder) &&
    video.name === name &&
    video.description === description &&
    video.paid === paid &&
    video.price[VIDEO_ACCESS_PERIOD.DAY] === Number.parseInt(price[VIDEO_ACCESS_PERIOD.DAY]) &&
    video.price[VIDEO_ACCESS_PERIOD.WEEK] === Number.parseInt(price[VIDEO_ACCESS_PERIOD.WEEK]) &&
    video.price[VIDEO_ACCESS_PERIOD.MONTH] === Number.parseInt(price[VIDEO_ACCESS_PERIOD.MONTH]) &&
    video.category === category
  )

  return (
    <form>
      <Notification open={openAlert} onClose={() => setOpenAlert(false)}>
        Něco se posralo...
      </Notification>
      <Stack mb={1}>
        <InputFileButton
          required
          value={videoFile}
          onChange={handleVideoFileChange}
          buttonProps={{ width: 145 }}
          inputName="video-file"
          supportedTypes=".webm"
          error={videoError !== null}
          errorMessage={videoError}
        >
          Vybrat video
        </InputFileButton>
        {uploadSubmitted && videoFile !== null && (
          <LinearProgress color="success" variant="determinate" value={videoProgress} sx={{ mt: 0.25, mb: 1 }} />
        )}
        <InputFileButton
          mt={1}
          required
          value={posterFile}
          onChange={handlePosterFileChange}
          buttonProps={{ width: 145 }}
          inputName="poster-file"
          supportedTypes=".jpg, .jpeg, .jpe"
          error={posterError !== null}
          errorMessage={posterError}
        >
          Vybrat náhled
        </InputFileButton>
        {uploadSubmitted && posterFile !== null && (
          <LinearProgress color="success" variant="determinate" value={posterProgress} sx={{ mt: 0.25 }} />
        )}
      </Stack>
      <FormControlLabel
        control={<Switch checked={paid} onChange={handlePaidChange} inputProps={{ 'aria-label': 'controlled' }} />}
        label="Placené video"
        labelPlacement="end"
        sx={{ mb: 1 }}
      />
      <FormControl fullWidth>
        <Stack direction="row">
          <TextField
            value={group}
            type="text"
            name="group"
            label="Skupina videí"
            sx={{ mb: 2, '& .MuiInputBase-root': { borderRadius: 3 } }}
            onChange={handleGroupChange}
          />
          <OrderInput
            value={groupOrder}
            onChange={handleOrderChange}
            name="group-order"
            label="Pořadí ve skupině"
            sx={{ width: 160, ml: 2 }}
          />
        </Stack>
        <TextField
          value={name}
          type="text"
          name="name"
          label="Název videa"
          sx={{ mb: 2, '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handleNameChange}
        />
        <TextField
          value={description}
          type="text"
          name="description"
          label="Popis videa"
          multiline
          rows={3}
          sx={{ mb: 2, '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handleDescriptionChange}
        />
        <Stack direction="row" mb={2}>
          <TextField
            value={category}
            select
            required
            label="Kategorie"
            defaultValue={video ? video.category : VIDEO_CATEGORY.ELEMENTARY}
            onChange={handleCategoryChange}
            sx={{
              mr: 2,
              '& .MuiInputBase-root': { borderRadius: 3, '& > input': { textAlign: 'right' } }
            }}
            fullWidth
          >
            {Object.values(VIDEO_CATEGORY).map(category => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </TextField>
          <Stack spacing={2}>
            <PriceInput
              value={price[VIDEO_ACCESS_PERIOD.DAY]}
              error={priceError[VIDEO_ACCESS_PERIOD.DAY]}
              onChange={handlePricePerDayChange}
              name="price-per-day"
              label="Cena na den"
              required={paid}
            />
            <PriceInput
              value={price[VIDEO_ACCESS_PERIOD.WEEK]}
              error={priceError[VIDEO_ACCESS_PERIOD.WEEK]}
              onChange={handlePricePerWeekChange}
              name="price-per-week"
              label="Cena na týden"
              required={paid}
            />
            <PriceInput
              value={price[VIDEO_ACCESS_PERIOD.MONTH]}
              error={priceError[VIDEO_ACCESS_PERIOD.MONTH]}
              onChange={handlePricePerMonthChange}
              name="price-per-month"
              label="Cena na měsíc"
              required={paid}
            />
          </Stack>
        </Stack>
        <Button
          sx={{ mt: 5, borderRadius: 3, fontSize: 16, py: 1.5, textTransform: 'none' }}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          disabled={loading || (editing && !difference)}
        >
          {editing ? 'Uložit změny' : 'Nahrát video'}{' '}
          {loading && <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />}
        </Button>
      </FormControl>
    </form>
  )
}
