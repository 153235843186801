import { deepEqual } from '@tanstack/react-router'
import { pushData, updateData, updateFileMetadata, uploadFile } from 'services/firebase'

const METADATA_KEY_PAID_VIDEO = 'Paid video'
const createCustomVideoMetadata = (file, paid) => ({
  customMetadata: {
    'Original File Name': file.name,
    [METADATA_KEY_PAID_VIDEO]: paid
  }
})

const createCustomPosterMetadata = file => ({
  customMetadata: {
    'Original File Name': file.name
  }
})

const getProgress = snapshot =>
  snapshot.totalBytes === 0 ? 100 : (snapshot.bytesTransferred / snapshot.totalBytes) * 100

export function uploadVideo(videoFile, posterFile, details, onVideoUploadProgressChange, onPosterUploadProgressChange) {
  return new Promise((resolve, reject) => {
    pushData('videos', details)
      .then(result => {
        const videoID = result.id
        const videoMetadata = createCustomVideoMetadata(videoFile, details.paid)
        uploadFile(`videos/${videoID}`, videoFile, videoMetadata, snapshot =>
          onVideoUploadProgressChange(getProgress(snapshot))
        )
          .then(() => {
            if (posterFile !== null) {
              const posterMetadata = createCustomPosterMetadata(posterFile)
              uploadFile(`posters/${videoID}`, posterFile, posterMetadata, snapshot =>
                onPosterUploadProgressChange(getProgress(snapshot))
              )
                .then(resolve)
                .catch(reject)
            } else {
              resolve()
            }
          })
          .catch(reject)
      })
      .catch(reject)
  })
}

export function updateVideo(
  currentVideo,
  videoFile,
  posterFile,
  details,
  onVideoUploadProgressChange,
  onPosterUploadProgressChange
) {
  // calculate the difference
  const difference = {}
  for (const [key, value] of Object.entries(details)) {
    if (!deepEqual(currentVideo[key], details[key])) {
      difference[key] = value
    }
  }
  return new Promise((resolve, reject) => {
    updateData(`/videos/${currentVideo.id}`, difference)
      .then(() => {
        if (videoFile != null) {
          const paid = 'paid' in difference ? difference.paid : currentVideo.paid
          const videoMetadata = createCustomVideoMetadata(videoFile, paid)
          uploadFile(`videos/${currentVideo.id}`, videoFile, videoMetadata, snapshot =>
            onVideoUploadProgressChange(getProgress(snapshot))
          )
            .then(() => {
              if (posterFile != null) {
                const posterMetadata = createCustomPosterMetadata(posterFile)
                uploadFile(`posters/${currentVideo.id}`, posterFile, posterMetadata, snapshot =>
                  onPosterUploadProgressChange(getProgress(snapshot))
                )
                  .then(resolve)
                  .catch(reject)
              } else {
                resolve()
              }
            })
            .catch(reject)
        } else {
          if (posterFile != null) {
            const posterMetadata = createCustomPosterMetadata(posterFile)
            uploadFile(`posters/${currentVideo.id}`, posterFile, posterMetadata, snapshot =>
              onPosterUploadProgressChange(getProgress(snapshot))
            )
              .then(() => {
                // video's metadata has to be updated if 'paid' status changes
                if ('paid' in difference) {
                  updateFileMetadata(`videos/${currentVideo.id}`, {
                    customMetadata: { [METADATA_KEY_PAID_VIDEO]: difference.paid }
                  })
                    .then(resolve)
                    .catch(reject)
                } else {
                  resolve()
                }
              })
              .catch(reject)
          } else {
            // video's metadata has to be updated if 'paid' status changes
            if ('paid' in difference) {
              updateFileMetadata(`videos/${currentVideo.id}`, {
                customMetadata: { [METADATA_KEY_PAID_VIDEO]: difference.paid }
              })
                .then(resolve)
                .catch(reject)
            } else {
              resolve()
            }
          }
        }
      })
      .catch(reject)
  })
}
