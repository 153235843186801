import { useState } from 'react'
import { styled } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import Subsection from 'components/Subsection'
import Text from 'components/Text'
import { getCreditsString } from 'utils/string'
import { getCredits, useOffers } from 'pages/top-up-funds'
import OfferEdit from './OfferEdit'
import Tooltip from 'components/Tooltip'

const HeaderCell = styled(props => (
  <TableCell align="center" sx={{ fontWeight: 800, userSelect: 'none' }} {...props} />
))({})
const BodyCell = styled(props => <TableCell align="right" sx={{ userSelect: 'none' }} {...props} />)({})

export default function Offers(props) {
  const [selectedOffer, setSelectedOffer] = useState(null)
  const offers = useOffers()
  const handleOfferClose = () => setSelectedOffer(null)

  return (
    <Subsection name="Nabídky kreditů" {...props}>
      <OfferEdit offer={selectedOffer} onClose={handleOfferClose} />
      {offers.isError && (
        <Stack direction="row" alignItems="center" color="error.main">
          <CancelIcon sx={{ fontSize: 20, mr: 0.5 }} />
          <Text fontWeight={600}>Chyba! Nepodařilo se načíst informace o nabídkách.</Text>
        </Stack>
      )}
      {offers.isLoading && <Skeleton variant="rounded" width={240} height={62} />}
      {offers.isSuccess && (
        <Paper elevation={4} sx={{ width: 'max-content', maxWidth: '100%', mt: 1, overflow: 'auto' }}>
          <TableContainer>
            <Table size="small" sx={{ width: 'max-content' }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'background.table.header' }}>
                  <HeaderCell>ID nabídky</HeaderCell>
                  <HeaderCell>Cena</HeaderCell>
                  <HeaderCell align="right">Bonus</HeaderCell>
                  <HeaderCell>Kredity</HeaderCell>
                  <HeaderCell></HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offers.data.map(offer => (
                  <TableRow key={offer.id} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <BodyCell align="center">
                      <Text fontFamily="Roboto" color="text.secondary">
                        {offer.id}
                      </Text>
                    </BodyCell>
                    <BodyCell>{offer.price}&nbsp;Kč</BodyCell>
                    <BodyCell>
                      <Text color={offer.bonus ? 'primary.main' : 'text.primary'} fontWeight={offer.bonus ? 600 : 500}>
                        {offer.bonus}&nbsp;%
                      </Text>
                    </BodyCell>
                    <BodyCell>
                      {getCredits(offer)}&nbsp;{getCreditsString(getCredits(offer))}
                    </BodyCell>
                    <BodyCell sx={{ p: 0, pr: 0.5 }}>
                      <Tooltip arrow placement="right" text="Upravit nabídku">
                        <IconButton aria-label="edit" size="small" onClick={() => setSelectedOffer(offer)}>
                          <EditIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                      </Tooltip>
                    </BodyCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Subsection>
  )
}
