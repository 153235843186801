import { memo } from 'react'
import { createRootRoute, Navigate, Outlet } from '@tanstack/react-router'

// cache
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { cacheConfig } from 'data/cacheConfig'

// contexts
import { UserContextProvider } from 'features/authentication'
import { ThemeContextProvider } from 'contexts'

// devtools
import { logComponentRendering } from 'utils/debug'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import RouterDevtools from '../components/RouterDevtools'

// layouts
import { ContentLayout, HeaderLayout } from 'layouts'
import { Navbar } from 'features/navbar'

const queryClient = new QueryClient(cacheConfig)

const RootComponent = memo(function RootComponent() {
  logComponentRendering('RootComponent')
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <ThemeContextProvider>
          <HeaderLayout>
            <Navbar />
          </HeaderLayout>
          <ContentLayout>
            <Outlet />
          </ContentLayout>
          <RouterDevtools />
          <ReactQueryDevtools />
        </ThemeContextProvider>
      </UserContextProvider>
    </QueryClientProvider>
  )
})

export const Route = createRootRoute({
  component: RootComponent,
  notFoundComponent: () => <Navigate to="/" replace />
})
