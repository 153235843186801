import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import React from 'react'

export default function Notification({ open, onClose, severity = 'error', hideAfter = 5000, sx, ...props }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={hideAfter}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={onClose} severity={severity} variant="filled" sx={{ width: '100%', ...sx }} {...props} />
    </Snackbar>
  )
}
