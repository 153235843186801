import { forwardRef } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import RuleIcon from '@mui/icons-material/Rule'

const InclusionIcon = forwardRef(({ included = false, size = 18, sx, ...props }, ref) => {
  const Icon =
    included === 'between' ? RuleIcon
    : included ? CheckIcon
    : ClearIcon
  const color =
    included === 'between' ? 'info'
    : included ? 'success'
    : 'error'
  return <Icon ref={ref} color={color} sx={{ fontSize: size, ...sx }} {...props} />
})

export default InclusionIcon
