import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

import Stack from '@mui/material/Stack'
import Text from 'components/Text'
import { useUser } from 'features/authentication'

export default function Confirmation({ step }) {
  const user = useUser()
  const isSuccess = step === 3
  const Icon = isSuccess ? CheckCircleIcon : CancelIcon
  const color = isSuccess ? 'success' : 'error'
  return (
    <Stack justifyContent="center" direction="row">
      <Icon color={color} sx={{ fontSize: 30, mr: 1 }} />
      <Stack alignItems="center" spacing={1}>
        <Text color={`${color}.main`} fontSize={18} disableUserSelection align="center">
          {isSuccess ?
            'Platba a následné dobití kreditů bylo úspěšné.'
          : 'Dobití kreditů bylo neúspěšné z důvodů zrušení platby.'}
        </Text>
        <Text color="text.secondary" disableUserSelection>
          Váš aktuální zůstatek je{' '}
          <Text variant="span" color="text.primary" fontWeight={500} align="center">
            {user.data.wallet} kreditů
          </Text>
          .
        </Text>
      </Stack>
    </Stack>
  )
}
