import Backdrop from 'components/Backdrop'
import UploadForm from './UploadForm'

export default function VideoEdit({ video, onSuccess }) {
  return (
    <Backdrop open={video !== null} onClose={onSuccess} title="Upravit video">
      {video && <UploadForm editing video={video} onSuccess={onSuccess} />}
    </Backdrop>
  )
}
