import MuiAppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'

export default function AppBar({ position = 'sticky', component, children, ...props }) {
  return (
    <MuiAppBar component={component} position={position} {...props}>
      <Container maxWidth="xl">
        <Toolbar variant="dense" disableGutters>
          {children}
        </Toolbar>
      </Container>
    </MuiAppBar>
  )
}
