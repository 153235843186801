import { RouterProvider as TanstackRouterProvider, createRouter } from '@tanstack/react-router'
import { routeTree } from '../utils/routeTree'

// TODO: implement data prefetching for Link's 'intent' settings(prefetch when hovered over a link)
// Create a new router instance
const router = createRouter({ routeTree /* defaultPreload: 'intent', defaultPreloadDelay: 250*/ })

export default function RouterProvider(props) {
  return <TanstackRouterProvider router={router} {...props} />
}
