import Skeleton from '@mui/material/Skeleton'
import { Navigate, createFileRoute } from '@tanstack/react-router'
import { useUser } from 'features/authentication'
import { BalanceHistory } from 'pages'

function BalanceHistoryWrapper() {
  const user = useUser()
  return (
    user.isLoading ? <Skeleton variant="rectangular" height="100%" />
    : user.isLoggedIn ? <BalanceHistory />
    : <Navigate to="/" />
  )
}

export const Route = createFileRoute('/balance-history')({
  component: BalanceHistoryWrapper
})
