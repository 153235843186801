import Box from '@mui/material/Box'
import Text from 'components/Text'

export default function Subsection({ name, children, ...props }) {
  return (
    <Box pb={1} {...props}>
      <Text variant="span" fontWeight={700} fontSize={15} color="text.secondary">
        {name}
      </Text>
      <Box py={1}>{children}</Box>
    </Box>
  )
}
