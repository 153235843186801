import { useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { getCreditsString } from 'utils/string'

export default function PriceInput({ value, error, onChange, name, label, required, sx }) {
  const [emptyString, setEmptyString] = useState(false)
  const handleChange = event => {
    const price = String(event.target.value).trim()
    if (price === '' || (!isNaN(price) && !isNaN(parseFloat(price)))) {
      setEmptyString(price === '')
      onChange && onChange(price)
    }
  }

  return (
    <TextField
      value={value}
      error={error || emptyString}
      helperText={error || emptyString ? 'Ondro, zadej cenu!' : null}
      type="text"
      name={name}
      label={label}
      sx={{ minWidth: 200, '& .MuiInputBase-root': { borderRadius: 3, '& > input': { textAlign: 'right' } }, ...sx }}
      onChange={handleChange}
      InputProps={{
        endAdornment: <InputAdornment position="end">{getCreditsString(value)}</InputAdornment>
      }}
      required={required}
      disabled={!required}
    />
  )
}
