import Backdrop from 'components/Backdrop'
import UploadForm from './UploadForm'

export default function UploadVideoBackdrop({ open, onClose, onSuccess }) {
  const handleSuccess = () => {
    onClose && onClose()
    onSuccess && onSuccess()
  }
  return (
    <Backdrop open={open} onClose={onClose} title="Nahrát video">
      <UploadForm onSuccess={handleSuccess} />
    </Backdrop>
  )
}
