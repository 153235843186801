// case insensitive natural sort
export function naturalSort(array, key = null, reverse = false, nestedKeys = false) {
  if (array == null) return []

  const getSortByValue = value => {
    if (key === null) return value
    if (typeof key === 'string') return value[key]
    if (key instanceof Array) {
      let result
      if (nestedKeys) {
        result = value
        for (const k of key) {
          result = result[k]
        }
      } else {
        result = ''
        for (const k of key) {
          result += value[k]
        }
      }
      return result
    }
  }
  return array.sort((a, b) =>
    String(getSortByValue(reverse ? b : a)).localeCompare(
      String(getSortByValue(reverse ? a : b)),
      navigator.languages[0] || navigator.language,
      {
        sensitivity: 'base',
        numeric: true,
        ignorePunctuation: false
      }
    )
  )
}
