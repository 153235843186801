import Section from 'components/Section'
import CategoryStats from './CategoryStats'
import Offers from './Offers'
import Payments from './Payments'
import VideoStats from './VideoStats'

export default function Administration() {
  return (
    <Section id="administration" name="Administrace" sx={{ textAlign: 'justify', height: 1 }}>
      <Payments mb={2} />
      <CategoryStats mb={2} />
      <VideoStats mb={2} />
      <Offers />
    </Section>
  )
}
