import { useTheme } from '@mui/material'
import { memo, useContext } from 'react'
import { ColorModeContext } from 'contexts'
import RoundIconButton from 'components/RoundIconButton'
import Tooltip from 'components/Tooltip'

import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'

export default memo(function ThemeToggler(props) {
  const theme = useTheme()
  const colorMode = useContext(ColorModeContext)

  return (
    <Tooltip text={`Změnit na ${theme.palette.mode === 'dark' ? 'světlý' : 'tmavý'} režim`} arrow>
      <RoundIconButton
        sx={{ ml: 1 }}
        Icon={theme.palette.mode === 'light' ? Brightness7Icon : Brightness4Icon}
        onClick={colorMode.toggleTheme}
        {...props}
      />
    </Tooltip>
  )
})
