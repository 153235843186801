import { VIDEO_ACCESS_PERIOD } from 'features/video'

export function getPeriodString(period) {
  switch (period) {
    case VIDEO_ACCESS_PERIOD.DAY:
      return 'den'
    case VIDEO_ACCESS_PERIOD.WEEK:
      return 'týden'
    case VIDEO_ACCESS_PERIOD.MONTH:
      return 'měsíc'
    default:
      return ''
  }
}
