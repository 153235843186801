import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export default function useCacheInvalidation() {
  // Get QueryClient from the context
  const queryClient = useQueryClient()
  const invalidateQuery = useCallback(
    queryKey => {
      queryClient.invalidateQueries({
        queryKey: Array.isArray(queryKey) ? queryKey : [queryKey]
      })
    },
    [queryClient]
  )

  return invalidateQuery
}
