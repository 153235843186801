/* TODO: if the query stale time is NOT set to infinity(periodical refetches), memoize and move the pre-processing functions
         of the data after the fetching to the 'select' method of individual queries (better optimizations) */
export const cacheConfig = {
  defaultOptions: {
    queries: {
      retry: 2,
      retryDelay: attempt => attempt * attempt * 1000,
      staleTime: Infinity, // The time in milliseconds after data is considered stale.
      gcTime: Infinity // Garbage collection time: The time in milliseconds that unused/inactive cache data remains in memory.
    }
  }
}
