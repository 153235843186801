import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Stack from '@mui/material/Stack'
import Text from 'components/Text'

export default function VideoGroup({ index, groupName, children }) {
  const noVideos = children.every(item => item === false)

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`group-${index + 1}-content`}
        id={`group-${index + 1}-header`}
      >
        <Text variant="h6" fontWeight={600}>
          {groupName}
        </Text>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" flexWrap="wrap" justifyContent={{ xs: 'center', md: 'flex-start' }}>
          {noVideos ?
            <Text color="text.secondary" disableUserSelection>
              Žádná zakoupená videa v této kategorii.
            </Text>
          : children}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
