import { useState } from 'react'

export default function useLocalStorage(key, defaultValue) {
  const [localStorageValue, setLocalStorageValue] = useState(() => {
    try {
      const value = localStorage.getItem(key)
      // existing value
      if (value) {
        return JSON.parse(value)
        // default value
      } else {
        localStorage.setItem(key, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (error) {
      localStorage.setItem(key, JSON.stringify(defaultValue))
      return defaultValue
    }
  })

  // update localStorage and state
  const setLocalStorageStateValue = valueOrFn => {
    const newValue = valueOrFn instanceof Function ? valueOrFn(localStorageValue) : valueOrFn
    localStorage.setItem(key, JSON.stringify(newValue))
    setLocalStorageValue(newValue)
  }

  return [localStorageValue, setLocalStorageStateValue]
}
