import { useRef, useState } from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import SocialButton, { SOCIAL_BUTTON_TYPE } from './SocialButton'
import Text from 'components/Text'
import { BACKDROP_CONTENT_TYPE } from '../data/constants'
import { useUser } from '../hooks/useUser'
import { validateEmail } from 'utils/validation'

export default function SignIn({ onClose, onContentChange, ...props }) {
  const user = useUser()

  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [unknownError, setUnknownError] = useState(false)

  const handleResetPasswordClick = event => {
    event.preventDefault()
    onContentChange(BACKDROP_CONTENT_TYPE.RESET_PASSWORD)
  }

  const handleRegisterClick = event => {
    event.preventDefault()
    onContentChange(BACKDROP_CONTENT_TYPE.REGISTER)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (!submitted) {
      setSubmitted(true)
    }

    // email validation
    if (emailRef.current.value.length === 0) {
      setEmailError('Zadejte Váš přihlašovací e-mail.')
      return
    }
    if (!validateEmail(emailRef.current.value)) {
      setEmailError('Neplatná e-mailová adresa.')
      return
    } else {
      setEmailError(null)
    }

    // password validation
    if (passwordRef.current.value.length === 0) {
      setPasswordError('Zadejte heslo!')
      return
    } else {
      setPasswordError(null)
    }

    try {
      setLoading(true)
      await user.login(emailRef.current.value, passwordRef.current.value)
      setLoading(false)
      onClose && onClose()
    } catch (e) {
      if (e.code === 'auth/invalid-credential') {
        setPasswordError('Zadaná kombinace e-mailu a hesla je neplatná!')
      } else {
        setUnknownError(true)
      }
      setLoading(false)
    }
  }

  const handleEmailChange = event => {
    if (submitted) {
      setEmailError(validateEmail(emailRef.current.value) ? null : 'Neplatná e-mailová adresa.')
    }
  }

  const handlePasswordChange = event => {
    if (submitted) {
      setPasswordError(passwordRef.current.value.length === 0 ? 'Zadejte heslo!' : null)
    }
  }

  const handleGoogleClick = async event => {
    event.preventDefault()

    try {
      setLoading(true)
      await user.loginWithGoogle()
      setLoading(false)
      onClose && onClose()
    } catch {
      setLoading(false)
    }
  }

  const handleFacebookClick = async event => {
    event.preventDefault()

    try {
      setLoading(true)
      await user.loginWithFacebook()
      setLoading(false)
      onClose && onClose()
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <>
      <Snackbar
        open={unknownError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setUnknownError(false)}
      >
        <Alert severity="error" variant="filled" onClose={() => setUnknownError(false)}>
          Vyskytla se neznámá chyba.
        </Alert>
      </Snackbar>
      <FormControl component="form" fullWidth>
        <TextField
          inputRef={emailRef}
          error={emailError != null}
          helperText={emailError}
          type="email"
          name="email"
          label="Přihlašovací e-mail"
          required
          autoFocus
          autoComplete="on"
          sx={{ mb: 2, '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handleEmailChange}
        ></TextField>
        <TextField
          inputRef={passwordRef}
          error={passwordError != null}
          helperText={passwordError}
          type="password"
          name="password"
          label="Heslo"
          required
          autoComplete="on"
          sx={{ '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handlePasswordChange}
        ></TextField>
        <Text color="text.secondary" fontSize={11} sx={{ mt: 0.5, textAlign: 'right', textDecoration: 'underline' }}>
          <a href="/reset-password" onClick={handleResetPasswordClick}>
            Zapomněli jste heslo?
          </a>
        </Text>
        <Button
          sx={{ mt: 3, borderRadius: 3, fontSize: 16, py: 1.5, textTransform: 'none' }}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          Přihlásit {loading && <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />}
        </Button>
      </FormControl>
      <Stack my={4} direction="row" alignItems="center" justifyContent="space-between">
        <Text variant="span" sx={{ height: '1px', backgroundColor: 'text.secondary', display: 'inline-block' }} />
        <Text color="text.secondary" fontSize={11} sx={{ px: 2, textAlign: 'center', userSelect: 'none' }}>
          nebo se přihlaste pomocí
        </Text>
        <Text variant="span" sx={{ height: '1px', backgroundColor: 'text.secondary', display: 'inline-block' }} />
      </Stack>
      <Stack direction="row" justifyContent="space-evenly">
        <SocialButton type={SOCIAL_BUTTON_TYPE.GOOGLE} onClick={handleGoogleClick} />
        <SocialButton type={SOCIAL_BUTTON_TYPE.FACEBOOK} onClick={handleFacebookClick} />
      </Stack>
      <Text color="text.secondary" fontSize={11} sx={{ mt: 2, textAlign: 'center' }} disableUserSelection>
        Ještě nemáte účet?{' '}
        <a href="/register" onClick={handleRegisterClick}>
          Registrujte se.
        </a>
      </Text>
    </>
  )
}
