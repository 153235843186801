import Skeleton from '@mui/material/Skeleton'
import { Navigate, createFileRoute } from '@tanstack/react-router'
import { useUser } from 'features/authentication'
import { TopUpFunds } from 'pages'

function TopUpFundsWrapper() {
  const user = useUser()
  return (
    user.isLoading ? <Skeleton variant="rectangular" height="100%" />
    : user.isLoggedIn ? <TopUpFunds />
    : <Navigate to="/" />
  )
}

export const Route = createFileRoute('/top-up-funds')({
  component: TopUpFundsWrapper
})
