import useCacheData from 'hooks/useCacheData'
import { getFileURL } from 'services/firebase'

async function fetchPosterURL(posterName) {
  const data = await getFileURL(`posters/${posterName}`)
  return data
}

export default function usePosterURL(posterName) {
  return useCacheData(['poster', posterName], () => fetchPosterURL(posterName))
}
