import { useState } from 'react'

export default function useSessionStorage(key, defaultValue) {
  const [sessionStorageValue, setSessionStorageValue] = useState(() => {
    try {
      const value = sessionStorage.getItem(key)
      // existing value
      if (value) {
        return JSON.parse(value)
        // default value
      } else {
        sessionStorage.setItem(key, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (error) {
      sessionStorage.setItem(key, JSON.stringify(defaultValue))
      return defaultValue
    }
  })

  // update sessionStorage and state
  const setSessionStorageStateValue = valueOrFn => {
    const newValue = valueOrFn instanceof Function ? valueOrFn(sessionStorageValue) : valueOrFn
    sessionStorage.setItem(key, JSON.stringify(newValue))
    setSessionStorageValue(newValue)
  }

  return [sessionStorageValue, setSessionStorageStateValue]
}
