/* https://mui.com/material-ui/customization/color/#2014-material-design-color-palettes */
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import { blue, grey } from '@mui/material/colors'

/* Default colors
  primary - for primary interface elements.
  secondary - for secondary interface elements.
  error - for elements that the user should be made aware of.
  warning - for potentially dangerous actions or important messages.
  info - for highlighting neutral information.
  success - for indicating the successful completion of an action that the user triggered. */
const lightColorPalette = {
  secondary: {
    main: blue[100]
  },
  background: {
    light: grey[200],
    table: { header: grey[300] }
  }
}

const darkColorPalette = {
  secondary: grey,
  background: {
    light: 'inherit',
    table: { header: grey[800] }
  }
}

const typography = {
  fontSize: 12,
  fontFamily: ['Poppins', 'Roboto', 'sans-serif'].join(','),
  fontWeightRegular: 400
  // there is an option to add custom typography variant
}

/* The contrastText is calculated using the contrastThreshold value, to maximize the contrast between the background and the text. */
const contrastThreshold = 2.8 // Default: 3

/* The light and dark tokens are calculated using the tonalOffset value, to shift the main color's luminance.
   A higher tonal offset value will make light tokens lighter, and dark tokens darker. */
const tonalOffset = {
  // Default: 0.2
  light: 0.3,
  dark: 0.2
}

const responsiveFontOptions = {
  breakpoints: ['xs', 'md', 'xl'],
  factor: 2 // This value determines the strength of font size resizing. The higher the value, the less difference there is between font sizes on small screens.
}

export const createCustomTheme = mode => {
  const config = {
    palette: {
      mode,
      contrastThreshold: contrastThreshold,
      tonalOffset: tonalOffset,
      ...(mode === 'light' ? lightColorPalette : darkColorPalette)
    },
    typography: typography
  }
  return responsiveFontSizes(createTheme(config), responsiveFontOptions)
}
