import { useEffect } from 'react'
import useCacheData from 'hooks/useCacheData'
import { getCollection, onCollectionChange } from 'services/firebase'
import useCacheInvalidation from 'hooks/useCacheInvalidation'

async function fetchAllVideos() {
  const result = []
  const videos = await getCollection('videos')
  videos.forEach(doc => {
    result.push({ id: doc.id, ...doc.data() })
  })
  return result
}

export default function useAllVideos() {
  const invalidateQuery = useCacheInvalidation()

  useEffect(() => {
    const unsubscribe = onCollectionChange('/videos', () => {
      invalidateQuery('video-stats')
      invalidateQuery('all-videos')
    })
    return () => unsubscribe('/videos')
  }, [invalidateQuery])

  return useCacheData(['all-videos'], fetchAllVideos)
}
