import { getFunctions, httpsCallable } from 'firebase/functions'
import app from './app'

const functions = getFunctions(app)

export const buyVideo = httpsCallable(functions, 'buyVideo')
export const checkVideoAccess = httpsCallable(functions, 'checkVideoAccess')
export const createPayment = httpsCallable(functions, 'createPayment')
export const getBalanceHistory = httpsCallable(functions, 'getBalanceHistory')
export const getPaymentSummary = httpsCallable(functions, 'getPaymentSummary')
export const getVideoStats = httpsCallable(functions, 'getVideoStats')
