import { createFileRoute, useSearch } from '@tanstack/react-router'
import { VideoLibrary } from 'pages'

function VideoLibraryWrapper() {
  const searchParams = useSearch()
  return <VideoLibrary urlTabID={searchParams.tabID ?? 0} />
}

export const Route = createFileRoute('/video-library')({
  component: VideoLibraryWrapper
})
