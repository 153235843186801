import { useRef, useState } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Text from 'components/Text'
import { BACKDROP_CONTENT_TYPE } from '../data/constants'
import { useUser } from '../hooks/useUser'
import { validateEmail } from 'utils/validation'

export default function ResetPassword({ onClose, onContentChange, ...props }) {
  const user = useUser()

  const emailRef = useRef(null)

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [emailError, setEmailError] = useState(null)

  const handleSignInClick = event => {
    event.preventDefault()
    onContentChange(BACKDROP_CONTENT_TYPE.SIGNIN)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (!submitted) {
      setSubmitted(true)
    }

    // email validation
    if (emailRef.current.value.length === 0) {
      setEmailError('Zadejte Váš přihlašovací e-mail.')
      return
    }
    if (!validateEmail(emailRef.current.value)) {
      setEmailError('Neplatná e-mailová adresa.')
      return
    } else {
      setEmailError(null)
    }

    try {
      setLoading(true)
      await user.resetPassword(emailRef.current.value)
      setLoading(false)
      onClose && onClose()
    } catch {
      setLoading(false)
      console.error('Během resetování hesla se vyskytla neznámá chyba!')
    }
  }

  const handleEmailChange = event => {
    if (submitted) {
      setEmailError(validateEmail(emailRef.current.value) ? null : 'Neplatná e-mailová adresa.')
    }
  }

  return (
    <>
      <FormControl component="form" fullWidth>
        <TextField
          inputRef={emailRef}
          error={emailError != null}
          helperText={emailError ?? 'Na zadaný e-mail Vám dojdou pokyny pro změnu Vašeho hesla.'}
          type="email"
          name="email"
          label="E-mail"
          required
          autoFocus
          autoComplete="on"
          sx={{ mb: 2, '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handleEmailChange}
        ></TextField>
        <Button
          sx={{ mt: 3, borderRadius: 3, fontSize: 16, py: 1.5, textTransform: 'none' }}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          Odeslat {loading && <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />}
        </Button>
      </FormControl>
      <Text color="text.secondary" fontSize={11} sx={{ mt: 2, textAlign: 'center' }} disableUserSelection>
        Vzpomněli jste si?{' '}
        <a href="/login" onClick={handleSignInClick}>
          Přihlaste se.
        </a>
      </Text>
    </>
  )
}
