import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from '@tanstack/react-router'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import VideoCategory from './VideoCategory'
import { useAllVideos, VIDEO_CATEGORY } from 'features/video'
import { Skeleton } from '@mui/material'

export default function VideoLibrary({ urlTabID = 0 }) {
  const [tabID, setTabID] = useState(urlTabID)
  const videos = useAllVideos()
  const navigate = useNavigate()

  const handleTabChange = (event, newValue) => navigate({ search: { tabID: newValue } })
  useEffect(() => setTabID(urlTabID), [urlTabID])

  const groupedVideos = useMemo(() => {
    const result = {
      [VIDEO_CATEGORY.UNIVERSITY]: {},
      [VIDEO_CATEGORY.HIGH_SCHOOL]: {},
      [VIDEO_CATEGORY.ELEMENTARY]: {}
    }
    if (videos.isSuccess) {
      videos.data.forEach(video => {
        if (video.group in result[video.category]) {
          result[video.category][video.group].push(video)
        } else {
          result[video.category][video.group] = [video]
        }
      })
    }
    return result
  }, [videos])

  return (
    <Box>
      <Tabs
        value={tabID}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Sekce videí"
      >
        <Tab value={0} label={VIDEO_CATEGORY.UNIVERSITY} />
        <Tab value={1} label={VIDEO_CATEGORY.HIGH_SCHOOL} />
        <Tab value={2} label={VIDEO_CATEGORY.ELEMENTARY} />
      </Tabs>
      {videos.isLoading ?
        <Skeleton variant="rounded" width="100%" height={500} sx={{ mt: 2 }} />
      : <>
          {tabID === 0 && <VideoCategory groupedVideos={groupedVideos[VIDEO_CATEGORY.UNIVERSITY]} />}
          {tabID === 1 && <VideoCategory groupedVideos={groupedVideos[VIDEO_CATEGORY.HIGH_SCHOOL]} />}
          {tabID === 2 && <VideoCategory groupedVideos={groupedVideos[VIDEO_CATEGORY.ELEMENTARY]} />}
        </>
      }
    </Box>
  )
}
