import { createContext, memo, useEffect, useMemo, useState } from 'react'
import { login, logout, resetPassword, loginWithGoogle, loginWithFacebook } from 'services/firebase'
import { onUserChange, registerUser } from '../utils/user'
import { getCurrentTimestamp } from 'utils/date'

export const UserContext = createContext({})

export default memo(function UserContextProvider({ children, ...props }) {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => onUserChange(setUser, () => setLoading(false)), [])

  const manager = useMemo(
    () => ({
      data: user,
      isLoading: loading,
      isLoggedIn: user != null,
      login,
      loginWithFacebook,
      loginWithGoogle,
      logout,
      register: registerUser,
      resetPassword,
      hasAccessTo: videoID => {
        if (user != null) {
          if (user.accessTo && videoID in user.accessTo) {
            return user.accessTo[videoID].until > getCurrentTimestamp()
          } else {
            return user.isAdmin || user.isDeveloper
          }
        }
        return false
      },
      hasAccessUntil: videoID => {
        if (user != null) {
          if (user.accessTo && videoID in user.accessTo) {
            return user.accessTo[videoID].until
          }
        }
        return null
      }
    }),
    [user, loading]
  )
  return (
    <UserContext.Provider value={manager} {...props}>
      {children}
    </UserContext.Provider>
  )
})
