import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Text from 'components/Text'
import { getCredits } from '../utils/price'
import useOffers from '../hooks/useOffers'

const OFFER_WIDTH = 180
const OFFER_HEIGHT = 180

export default function Offers({ onSelect }) {
  const offers = useOffers()

  return (
    <Stack direction="row" justifyContent="center" flexWrap="wrap">
      {offers.isLoading &&
        [...Array(4)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={OFFER_WIDTH}
            height={OFFER_HEIGHT}
            sx={{ borderRadius: 4, mr: index === 3 ? 0 : 2, mb: index === 3 ? 0 : 2 }}
          />
        ))}
      {offers.isError && (
        <Text variant="h5" color="error" fontWeight={600}>
          Nepodařilo se načít nabídky!
        </Text>
      )}
      {offers.isSuccess &&
        offers.data.map(offer => (
          <Paper
            key={offer.id}
            elevation={5}
            sx={{
              p: 3,
              mr: 2,
              mb: 2,
              borderRadius: 4,
              overflow: 'hidden',
              position: 'relative',
              width: OFFER_WIDTH,
              height: OFFER_HEIGHT
            }}
          >
            {offer.bonus > 0 && (
              <Box
                px={1}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  backgroundColor: 'warning.main',
                  borderBottomLeftRadius: 6
                }}
              >
                <Text fontSize={12} fontWeight={600}>
                  + {offer.bonus} %
                </Text>
              </Box>
            )}
            <Stack alignItems="center">
              {offer.bonus > 0 && (
                <Stack direction="row" alignItems="center">
                  <AccountBalanceIcon sx={{ fontSize: 16, mr: 0.5, color: 'text.secondary' }} />
                  <Text
                    fontWeight={600}
                    fontSize={16}
                    align="center"
                    disableUserSelection
                    color="text.secondary"
                    sx={{ textDecoration: 'line-through' }}
                  >
                    {offer.price} kreditů
                  </Text>
                </Stack>
              )}
              <Stack direction="row" alignItems="center" mb={3} mt={offer.bonus === 0 ? 3 : 0}>
                <AccountBalanceIcon sx={{ fontSize: 18, mr: 0.5 }} />
                <Text fontWeight={600} fontSize={18} noWrap disableUserSelection>
                  {getCredits(offer)} kreditů
                </Text>
              </Stack>
              <Text
                variant="span"
                fontWeight={600}
                fontSize={16}
                align="center"
                disableUserSelection
                color="text.secondary"
              >
                {offer.price} Kč
              </Text>
              <Button onClick={() => onSelect(offer.id)}>Vybrat</Button>
            </Stack>
          </Paper>
        ))}
    </Stack>
  )
}
