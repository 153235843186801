import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Tooltip from 'components/Tooltip'
import Link from 'components/Link'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  transition: `background ${theme.transitions.duration.short}ms, color ${theme.transitions.duration.short}ms`,
  '& > a': {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  '& .MuiSvgIcon-root': {
    transition: `color ${theme.transitions.duration.short}ms`
  },
  '&:hover': {
    color: theme.palette.text.secondary,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.dark
    }
  }
}))

// ref is required by tooltips to work properly
export default function DropdownMenuItem({
  text,
  fontWeight = 400,
  dense = false,
  Icon = null,
  disabled = false,
  tooltipTextOnDisabled = null,
  linkComponent = false,
  linkProps = {},
  children = null,
  ...props
}) {
  const padding = dense ? '6px 8px' : '6px 16px'
  const renderMenuItemContent = (
    <>
      {Icon !== null && <Icon sx={{ mr: 1, color: 'primary.main', fontSize: dense ? 16 : 18 }} />}
      {children === null ?
        <Typography fontWeight={linkComponent ? 'inherit' : fontWeight} fontSize={dense ? 12 : 14}>
          {text}
        </Typography>
      : children}
    </>
  )
  const renderMenuItem = (
    <StyledMenuItem sx={{ padding: linkComponent ? 0 : padding }} {...props} disabled={disabled}>
      {linkComponent ?
        <Link style={{ padding: padding }} {...linkProps}>
          {renderMenuItemContent}
        </Link>
      : renderMenuItemContent}
    </StyledMenuItem>
  )

  return disabled && tooltipTextOnDisabled !== null ?
      <Tooltip text={tooltipTextOnDisabled} placement="right" arrow offsetY={-6}>
        <Box sx={{ cursor: 'not-allowed' }}>{renderMenuItem}</Box>
      </Tooltip>
    : renderMenuItem
}
