import useCacheData from 'hooks/useCacheData'
import { getVideoStats } from 'services/firebase'
import { naturalSort } from 'utils/sort'

async function fetchVideoStats() {
  const videos = await getVideoStats()
  return naturalSort(videos?.data?.videoStats ?? [], ['paid', 'profit'], true)
}

export default function useVideoStats() {
  return useCacheData(['video-stats'], fetchVideoStats)
}
