import { memo } from 'react'
import Stack from '@mui/material/Stack'

import AccountMenu from './AccountMenu'
import LogoLink from './LogoLink'
import ThemeToggler from './ThemeToggler'
import NavLink from 'components/NavLink'
import { MAIN_PAGE_SECTION_ID } from 'pages'
import SmallScreenMenu from './SmallScreenMenu'

export default memo(function Navbar() {
  return (
    <>
      {/* =============== WIDE SCREENS =============== */}
      <LogoLink logoSize={42} display={{ xs: 'none', md: 'flex' }} mr={2}>
        Doučování - Lektor Ondřej
      </LogoLink>
      <Stack direction="row" flexGrow={1} display={{ xs: 'none', md: 'flex' }} spacing={2}>
        <NavLink hash={MAIN_PAGE_SECTION_ID.HOME}>Domů</NavLink>
        <NavLink hash={MAIN_PAGE_SECTION_ID.PRICING}>Ceník</NavLink>
        <NavLink hash={MAIN_PAGE_SECTION_ID.CONTACT}>Kontakt</NavLink>
        <NavLink to="video-library">Videotéka</NavLink>
      </Stack>

      {/* =============== NARROW SCREENS =============== */}
      <SmallScreenMenu flexGrow={1} display={{ xs: 'flex', md: 'none' }} />
      <LogoLink logoSize={32} display={{ xs: 'flex', md: 'none' }} flexGrow={1}>
        Doučování
      </LogoLink>
      {/* =============== COMMON =============== */}
      <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
        <ThemeToggler />
        <AccountMenu />
      </Stack>
    </>
  )
})
