import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import { useNavigate } from '@tanstack/react-router'
import Text from 'components/Text'

export default function InsufficientFunds({ balance, ...props }) {
  const navigate = useNavigate()
  const handleRedirect = () => navigate({ to: '/top-up-funds' })

  return (
    <Box {...props}>
      <Text color="error" disableUserSelection align="center">
        Pro zakoupení tohoto videa nemáte dostatečný zůstatek na svém účtu. Váš aktuální zůstatek je{' '}
        <Text variant="span" fontWeight={600}>
          {balance}
        </Text>{' '}
        kreditů.
      </Text>
      <Button
        fullWidth
        sx={{ mt: 5, borderRadius: 3, fontSize: 16, py: 1.5, textTransform: 'none' }}
        variant="contained"
        onClick={handleRedirect}
      >
        Dobít kredit
      </Button>
    </Box>
  )
}
