import { useState } from 'react'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Notification from 'components/Notification'
import Text from 'components/Text'
import { getCredits } from 'pages/top-up-funds'
import { updateOffer } from '../utils/offer'
import useCacheInvalidation from 'hooks/useCacheInvalidation'

export default function OfferForm({ offer, onSuccess }) {
  const [openAlert, setOpenAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [price, setPrice] = useState(offer.price)
  const [priceError, setPriceError] = useState(null)
  const [bonus, setBonus] = useState(offer.bonus)
  const [bonusError, setBonusError] = useState(null)

  const invalidateQuery = useCacheInvalidation()

  const handleSubmit = event => {
    event.preventDefault()

    if (!submitted) {
      setSubmitted(true)
    }

    const p = parseFloat(price)
    const b = parseFloat(bonus)

    let valid = true
    if (p <= 0) {
      valid = false
      setPriceError(true)
    }
    if (b < 0) {
      valid = false
      setBonusError(true)
    }

    if (valid) {
      const newData = {}
      if (p !== offer.price) {
        newData.price = p
      }
      if (b !== offer.bonus) {
        newData.bonus = b
      }
      setLoading(true)
      updateOffer(offer.id, newData)
        .then(() => {
          invalidateQuery('offers')
          onSuccess && onSuccess()
        })
        .catch(() => setOpenAlert(true))
        .finally(() => setLoading(false))
    }
  }

  const handlePriceChange = event => {
    const price = String(event.target.value).trim()
    if (price === '' || (!isNaN(price) && !isNaN(parseFloat(price)))) {
      setPriceError(price === '' || parseFloat(price) <= 0)
      setPrice(price)
    }
  }

  const handleBonusChange = event => {
    const bonus = String(event.target.value).trim()
    if (bonus === '' || (!isNaN(bonus) && !isNaN(parseFloat(bonus)))) {
      setBonusError(bonus === '' || parseFloat(bonus) < 0)
      setBonus(bonus)
    }
  }

  const credits = getCredits({ price: parseFloat(price), bonus: parseFloat(bonus) })
  const difference = !(offer.price === parseFloat(price) && offer.bonus === parseFloat(bonus))

  return (
    <form>
      <Notification open={openAlert} onClose={() => setOpenAlert(false)}>
        Něco se posralo...
      </Notification>
      <Stack justifyContent="center" alignItems="center">
        <TextField
          value={price}
          error={priceError}
          helperText={priceError ? 'Ondro, zadej platnou cenu!' : null}
          type="text"
          name="price"
          label="Cena"
          sx={{
            width: 200,
            mb: 3,
            '& .MuiInputBase-root': { borderRadius: 3, '& > input': { textAlign: 'right' } }
          }}
          onChange={handlePriceChange}
          required
          InputProps={{
            endAdornment: <InputAdornment position="end">Kč</InputAdornment>
          }}
        />
        <TextField
          value={bonus}
          error={bonusError}
          helperText={bonusError ? 'Ondro, zadej platný bonus!' : null}
          type="text"
          name="bonus"
          label="Bonus"
          sx={{
            width: 200,
            '& .MuiInputBase-root': { borderRadius: 3, '& > input': { textAlign: 'right' } }
          }}
          onChange={handleBonusChange}
          required
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />
        <Text fontSize={12} color="text.secondary" sx={{ mt: 1 }}>
          Výsledný počet kreditů: <Text variant="span">{credits ? credits : null}</Text>
        </Text>
        <Button
          sx={{ mt: 5, borderRadius: 3, fontSize: 16, py: 1.5, textTransform: 'none' }}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          disabled={loading || !difference}
        >
          Upravit nabídku {loading && <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />}
        </Button>
      </Stack>
    </form>
  )
}
