import { useEffect, useRef } from 'react'
import Skeleton from '@mui/material/Skeleton'
import usePosterURL from '../hooks/usePosterURL'
import useVideoURL from '../hooks/useVideoURL'

export default function Video({
  videoName,
  posterName,
  width,
  height,
  withoutControls = false,
  preload = false,
  ...props
}) {
  const videoRef = useRef(null)
  const poster = usePosterURL(posterName)
  const video = useVideoURL(videoName)

  // reload the video after the source change
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load && videoRef.current.load()
    }
  }, [video.data])

  return poster.isLoading || video.isLoading ?
      <Skeleton variant="rectangular" sx={{ paddingTop: '56%' }} />
    : <video
        ref={videoRef}
        onContextMenu={event => event.preventDefault()}
        {...(poster.isSuccess ? { poster: poster.data } : {})}
        {...(width ? { width } : {})}
        {...(height ? { height } : {})}
        {...(withoutControls ? {} : { controls: true })}
        preload={preload ? 'auto' : 'metadata'}
        {...props}
      >
        <source src={video.data} type="video/webm" />
        Váš prohlížeč nepodporuje videa.
      </video>
}
