import { useState } from 'react'
import { BACKDROP_CONTENT_TYPE } from '../data/constants'
import Backdrop from 'components/Backdrop'
import SignIn from './SignIn'
import Register from './Register'
import ResetPassword from './ResetPassword'

export default function AuthenticationBackdrop({ open, onClose, defaultContent, ...props }) {
  const [content, setContent] = useState(defaultContent ?? BACKDROP_CONTENT_TYPE.SIGNIN)
  const handleContentChange = content => setContent(content)

  return (
    <Backdrop open={open} onClose={onClose} title={content}>
      {content === BACKDROP_CONTENT_TYPE.SIGNIN && <SignIn onClose={onClose} onContentChange={handleContentChange} />}
      {content === BACKDROP_CONTENT_TYPE.REGISTER && (
        <Register onClose={onClose} onContentChange={handleContentChange} />
      )}
      {content === BACKDROP_CONTENT_TYPE.RESET_PASSWORD && (
        <ResetPassword onClose={onClose} onContentChange={handleContentChange} />
      )}
    </Backdrop>
  )
}
