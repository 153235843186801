import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  onSnapshot,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import app from './app'

const database = getFirestore(app)

export const getCollection = location => getDocs(collection(database, location))
export const getData = location => getDoc(doc(database, location))
export const setData = (location, data) => setDoc(doc(database, location), data)
export const updateData = (location, data) => updateDoc(doc(database, location), data)
export const pushData = (location, data) => addDoc(collection(database, location), data)
export const pushToArray = (location, arrayKey, data) =>
  updateDoc(doc(database, location), { [arrayKey]: arrayUnion(data) })
export const onDataChange = (location, callback) =>
  onSnapshot(doc(database, location), data => typeof callback === 'function' && callback(data.data()))
export const onCollectionChange = (location, callback) =>
  onSnapshot(
    collection(database, location),
    data => typeof callback === 'function' && callback(data.docs.map(doc => ({ id: doc.id, ...doc.data() })))
  )
export const atomicIncrement = (location, field, value) =>
  updateDoc(doc(database, location), { [field]: increment(value) })
