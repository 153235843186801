import { useMemo } from 'react'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Text from 'components/Text'
import { getCredits } from '../utils/price'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import PolicyConfirmationLabel from 'components/PolicyConfirmationLabel'
import useSessionStorage from 'hooks/useSessionStorage'
import { SELECTED_OFFER_STORAGE_KEY } from '../data/constants'
import useOffers from '../hooks/useOffers'
import { Skeleton } from '@mui/material'

export default function Recap({ onCancel, checked, onChange }) {
  const offers = useOffers()
  const offerID = useSessionStorage(SELECTED_OFFER_STORAGE_KEY, null)[0]
  if (offers.isError || offerID === null) {
    onCancel && onCancel()
  }

  const selectedOffer = useMemo(() => {
    if (offers.isSuccess) {
      let found = false
      for (const offer of offers.data) {
        if (offer.id === offerID) {
          return offer
        }
      }
      if (!found) {
        onCancel && onCancel()
      }
    } else {
      return { price: 0, bonus: 0 }
    }
    return { price: 0, bonus: 0 }
  }, [onCancel, offers, offerID])

  return (
    <>
      {offers.isLoading && <Skeleton variant="rounded" height={250} sx={{ width: { xs: 300, md: 500 }, mx: 'auto' }} />}
      {offers.isSuccess && (
        <Stack alignItems="center">
          <Paper elevation={5} sx={{ p: 3, mb: 3, borderRadius: 4, minWidth: 360, maxWidth: 'max-content' }}>
            <Text variant="h6" fontWeight={600}>
              Souhrn objednávky
            </Text>
            <Divider sx={{ my: 1 }} />
            <Stack direction="row" alignItems="center" spacing={0.2}>
              <Stack direction="row" justifyContent="space-between" flexGrow={1}>
                <Text color="text.secondary">Nákup</Text>
                <Text color="primary.main" fontWeight={500}>
                  {getCredits(selectedOffer)} kreditů
                </Text>
              </Stack>
              <IconButton aria-label="delete" onClick={onCancel} color="error" size="small">
                <ClearIcon sx={{ fontSize: 14 }} />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 1 }} />
            <Stack direction="row" justifyContent="space-between" fontWeight={600}>
              <Text>Celkem s DPH:</Text>
              <Text>{selectedOffer.price} Kč</Text>
            </Stack>
          </Paper>
          <Stack direction="row" alignItems="center">
            <Checkbox checked={checked} onChange={onChange} sx={{ height: 'max-content' }} />
            <PolicyConfirmationLabel prefix="Odesláním objednávky" contractWithdrawal />
          </Stack>
        </Stack>
      )}
    </>
  )
}
