import app from './app'
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  // providers
  FacebookAuthProvider,
  GoogleAuthProvider,
  updateProfile
} from 'firebase/auth'

// providers
const facebookProvider = new FacebookAuthProvider()
const googleProvider = new GoogleAuthProvider()

export const auth = getAuth(app)
export const login = (email, password) => signInWithEmailAndPassword(auth, email, password)
export const logout = () => signOut(auth)
export const register = (email, password) => createUserWithEmailAndPassword(auth, email, password)
export const updateUserProfile = (user, data) => updateProfile(user, data)
export const onUserStateChange = callback => onAuthStateChanged(auth, callback)
export const resetPassword = email => sendPasswordResetEmail(auth, email)

export const loginWithFacebook = () => signInWithPopup(auth, facebookProvider)
export const loginWithGoogle = () => signInWithPopup(auth, googleProvider)
