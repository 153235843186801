import { forwardRef } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'

const StyledButton = styled(Button)({
  padding: 6,
  borderRadius: '50%',
  height: 'max-content',
  minWidth: 'max-content'
})

// ref is required by tooltips to work properly
const RoundIconButton = forwardRef(
  ({ Icon, color = 'secondary', variant = 'contained', size = 18, onClick = null, loading = false, ...props }, ref) => {
    return (
      <StyledButton disabled={loading} variant={variant} color={color} ref={ref} onClick={onClick} {...props}>
        {loading ?
          <CircularProgress size={size} />
        : <Icon sx={{ fontSize: size }} />}
      </StyledButton>
    )
  }
)

export default RoundIconButton
