import { useQuery } from '@tanstack/react-query'

export default function useCacheData(queryKey, queryFn, queryOptions = {}) {
  const { data, isLoading, isPending, isSuccess, isError, error } = useQuery({
    queryKey: typeof queryKey === 'string' ? [queryKey] : queryKey, // TanStack Query v5 requires the queryKey to be an array
    queryFn: queryFn,
    ...queryOptions
  })

  return { data, isLoading, isPending, isSuccess, isError, error }
}
