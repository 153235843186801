import Backdrop from 'components/Backdrop'
import OfferForm from './OfferForm'

export default function OfferEdit({ offer, onClose }) {
  return (
    <Backdrop open={offer !== null} onClose={onClose} title="Upravit nabídku" sx={{ minWidth: 200, width: 360 }}>
      {offer && <OfferForm offer={offer} onSuccess={onClose} />}
    </Backdrop>
  )
}
