import { useRef } from 'react'
import Box from '@mui/material/Box'
import MuiBackdrop from '@mui/material/Backdrop'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Text from './Text'

export default function Backdrop({ open, onClose, title, closeOnClick = false, children, sx, ...props }) {
  const backdropRef = useRef(null)

  const handleClose = () => {
    onClose && onClose()
  }
  const handleBackdropClick = e => {
    if (e.target === backdropRef.current) {
      closeOnClick && handleClose()
    }
  }

  return (
    <MuiBackdrop
      ref={backdropRef}
      open={open}
      onClick={handleBackdropClick}
      sx={{ p: 2, backgroundColor: 'rgba(0,0,0,.75)', overflow: 'auto', zIndex: 1200 }}
    >
      <Stack
        p={5}
        width={500}
        minHeight={320}
        maxHeight="100%"
        overflow="auto"
        sx={{
          backgroundColor: 'background.default',
          borderRadius: 3,
          ...sx
        }}
        {...props}
      >
        <Stack mb={5} direction="row" justifyContent="space-between">
          <Text variant="h5" fontWeight={600} color="text.primary" disableUserSelection>
            {title}
          </Text>
          <IconButton color="text.secondary" onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box>{children}</Box>
      </Stack>
    </MuiBackdrop>
  )
}
