export const EMPTY_NAME = 'Bez názvu'
export const EMPTY_GROUP = 'Bez zařazení'

export const VIDEO_ACCESS_PERIOD = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month'
}

export const VIDEO_CATEGORY = {
  ELEMENTARY: 'Základní škola',
  HIGH_SCHOOL: 'Střední škola',
  UNIVERSITY: 'Vysoká škola'
}

export const ERROR_MESSAGE = {
  NO_VIDEO_SELECTED: 'Ondro, musíš vybrat nějaký video!',
  NO_POSTER_SELECTED: 'Ondro, musíš vybrat nějaký náhled!',
  UNSUPPORTED_VIDEO_TYPE: 'Nepodporovaný typ videa. Podporovaný je pouze WEBM formát.',
  UNSUPPORTED_POSTER_TYPE: 'Nepodporovaný typ náhledu. Podporovaný je pouze JPEG formát.'
}
