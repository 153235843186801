import useURLParams from 'hooks/useURLParams'
import { useCallback } from 'react'

const STEP_KEY = 'step'

export default function useURLStep(defaultValue) {
  const { getParam, setParam } = useURLParams()
  const step = getParam(STEP_KEY)
  const setStep = useCallback(step => setParam(STEP_KEY, step, true), [setParam])

  return [step ?? defaultValue, setStep]
}
