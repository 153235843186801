import {
  checkVideoAccess,
  getData,
  logout,
  onDataChange,
  onUserStateChange,
  register,
  updateUserProfile
} from 'services/firebase'

export function registerUser(fullName, email, password) {
  return new Promise((resolve, reject) => {
    // register the user
    register(email, password)
      .then(userCredential =>
        updateUserProfile(userCredential.user, {
          displayName: fullName
        })
          .then(() => logout().then(resolve).catch(reject))
          .catch(reject)
      )
      .catch(reject)
  })
}

export function onUserChange(setUser, callback) {
  return onUserStateChange(user => {
    if (user == null) {
      setUser(current => {
        current && current.unsubscribe && current.unsubscribe(`users/${current.id}`)
        return null
      })
      callback()
      return
    } else {
      // check the user's access to videos
      checkVideoAccess().catch(e => console.error("Error checking user's video access:", e))

      const userData = {
        id: user.uid,
        email: user.email,
        displayName: user.displayName,
        profilePicture: user.photoURL
      }

      // get DB entry
      getData(`users/${userData.id}`).then(snapshot => {
        const serverData = snapshot.data()
        // add data change listener
        const unsubscribe = onDataChange(`users/${userData.id}`, data => setUser(current => ({ ...current, ...data })))
        setUser({
          ...userData,
          ...serverData,
          unsubscribe,
          isAdmin: serverData?.roles.includes('admin'),
          isDeveloper: serverData?.roles.includes('developer')
        })
        callback()
      })
    }
  })
}
