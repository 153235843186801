import Box from '@mui/material/Box'
import Text from 'components/Text'

export default function Section({ id, name, children, ...props }) {
  return (
    <Box id={id} component="section" py={2} {...props}>
      <Text variant="h5" fontWeight={800}>
        {name}
      </Text>
      <Box py={2}>{children}</Box>
    </Box>
  )
}
