import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormLabel from '@mui/material/FormLabel'
import Input from '@mui/material/Input'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import Stack from '@mui/material/Stack'
import { FormHelperText, IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

export default function InputFileButton({
  value,
  onChange,
  inputName,
  error,
  errorMessage,
  supportedTypes,
  buttonProps,
  required = false,
  children,
  ...props
}) {
  const handleInputChange = event => onChange && onChange(event.target.files[0])
  const handleClearClick = () => onChange && onChange(null)

  return (
    <Box {...props}>
      <Stack direction="row" alignItems="center">
        <Box>
          <Button
            size="small"
            component="label"
            variant="contained"
            tabIndex={-1}
            sx={{ mr: 1, borderRadius: 1.5, ...buttonProps }}
          >
            <Stack direction="row" alignItems="center" width={1} sx={{ textWrap: 'nowrap' }}>
              <InsertDriveFileIcon sx={{ mr: 1 }} />
              <Input
                id="file"
                inputProps={{ accept: supportedTypes }}
                sx={{ display: 'none' }}
                type="file"
                name={inputName}
                onChange={handleInputChange}
                required={required}
              />
              {children}
              {required && ' *'}
            </Stack>
          </Button>
        </Box>
        <FormLabel error={error}>{value?.name ?? 'Nevybrán žádný soubor'}</FormLabel>
        {value != null && (
          <IconButton sx={{ p: 0.5, ml: 0.5 }} color="error" onClick={handleClearClick}>
            <ClearIcon sx={{ fontSize: 16 }} />
          </IconButton>
        )}
      </Stack>
      <FormHelperText error={error}>{errorMessage}</FormHelperText>
    </Box>
  )
}
