import Box from '@mui/material/Box'
import Question from './Question'
import { QUESTIONS } from '../data/questions'

export default function FAQ(props) {
  return (
    <Box {...props}>
      {QUESTIONS.map((item, index) => (
        <Question key={index} index={index} question={item.question} answer={item.answer} />
      ))}
    </Box>
  )
}
