import { memo } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Link from 'components/Link'
import Section from 'components/Section'
import Subsection from 'components/Subsection'
import Text from 'components/Text'
import { CONTRACT_WITHDRAWAL_ID } from '../data/constants'
import { CONTACT } from 'data/constants'

const Subsubsection = ({ number, children, ...props }) => (
  <Stack direction="row" mb={1} pl={2} {...props}>
    <Box minWidth={number ? 12 * number.split('.').length : 25} mr={1}>
      <Text variant="span" color="text.secondary" fontWeight={500}>
        {number}
      </Text>
    </Box>
    <Text>{children}</Text>
  </Stack>
)

// TODO: make SECTION_ID global
export default memo(function TermsAndConditions() {
  return (
    <Section id="conditions-of-use" name="Obchodní podmínky" sx={{ textAlign: 'justify' }}>
      <Text mb={2}>
        Obchodní podmínky Ing. Ondřeje Kučery, MBA se sídlem Maršovská 2239, 68801 Uherský Brod, Česká republika,
        identifikační číslo: 21011281 pro prodej zboží prostřednictvím on-line obchodu umístěného na internetové adrese{' '}
        <Link navOpts={{ to: 'https://doucujimatematiku.cz' }}>https://doucujimatematiku.cz</Link>.
      </Text>
      <Subsection name="1. ÚVODNÍ USTANOVENÍ">
        <Subsubsection number="1.1">
          Tyto obchodní podmínky (dále jen „obchodní podmínky“) Ing. Ondřeje Kučery, MBA se sídlem Maršovská 2239, 68801
          Uherský Brod, Česká republika, identifikační číslo: 21011281 (dále jen „prodávající“) upravují v souladu s
          ustanovením § 1751 odst. 1 zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů (dále jen
          „občanský zákoník“) vzájemná práva a povinnosti smluvních stran vzniklé v souvislosti nebo na základě kupní
          smlouvy (dále jen „kupní smlouva“) uzavírané mezi prodávajícím a jinou fyzickou osobou (dále jen „kupující“)
          prostřednictvím internetového obchodu prodávajícího. Internetový obchod je prodávajícím provozován na webové
          stránce umístěné na internetové adrese{' '}
          <Link navOpts={{ to: 'https://doucujimatematiku.cz' }}>https://doucujimatematiku.cz</Link> (dále jen „webová
          stránka“), a to prostřednictvím rozhraní webové stránky (dále jen „webové rozhraní obchodu“).
        </Subsubsection>
        <Subsubsection number="1.2">
          Obchodní podmínky se nevztahují na případy, kdy osoba, která má v úmyslu nakoupit zboží od prodávajícího, je
          právnickou osobou či osobou, jež jedná při objednávání zboží v rámci své podnikatelské činnosti nebo v rámci
          svého samostatného výkonu povolání.
        </Subsubsection>
        <Subsubsection number="1.3">
          Ustanovení odchylná od obchodních podmínek je možné sjednat v kupní smlouvě. Odchylná ujednání v kupní smlouvě
          mají přednost před ustanoveními obchodních podmínek.
        </Subsubsection>
        <Subsubsection number="1.4">
          Ustanovení obchodních podmínek jsou nedílnou součástí kupní smlouvy. Kupní smlouva a obchodní podmínky jsou
          vyhotoveny v českém jazyce. Kupní smlouvu lze uzavřít v českém jazyce.
        </Subsubsection>
        <Subsubsection number="1.5">
          Znění obchodních podmínek může prodávající měnit či doplňovat. Tímto ustanovením nejsou dotčena práva a
          povinnosti vzniklá po dobu účinnosti předchozího znění obchodních podmínek.
        </Subsubsection>
      </Subsection>
      <Subsection name="2. UŽIVATELSKÝ ÚČET">
        <Subsubsection number="2.1">
          Na základě registrace kupujícího provedené na webové stránce může kupující přistupovat do svého uživatelského
          rozhraní. Ze svého uživatelského rozhraní může kupující provádět objednávání zboží (dále jen „uživatelský
          účet“). V případě, že to webové rozhraní obchodu umožňuje, může kupující provádět objednávání zboží též bez
          registrace přímo z webového rozhraní obchodu.{' '}
        </Subsubsection>
        <Subsubsection number="2.2">
          Při registraci na webové stránce a při objednávání zboží je kupující povinen uvádět správně a pravdivě všechny
          údaje. Údaje uvedené v uživatelském účtu je kupující při jakékoliv jejich změně povinen aktualizovat. Údaje
          uvedené kupujícím v uživatelském účtu a při objednávání zboží jsou prodávajícím považovány za správné.
        </Subsubsection>
        <Subsubsection number="2.3">
          Přístup k uživatelskému účtu je zabezpečen uživatelským jménem a heslem. Kupující je povinen zachovávat
          mlčenlivost ohledně informací nezbytných k přístupu do jeho uživatelského účtu.
        </Subsubsection>
        <Subsubsection number="2.4">
          Kupující není oprávněn umožnit využívání uživatelského účtu třetím osobám.
        </Subsubsection>
        <Subsubsection number="2.5">
          Prodávající může zrušit uživatelský účet, a to zejména v případě, kdy kupující svůj uživatelský účet déle než
          2 roky nevyužívá, či v případě, kdy kupující poruší své povinnosti z kupní smlouvy (včetně obchodních
          podmínek).
        </Subsubsection>
        <Subsubsection number="2.6">
          Kupující bere na vědomí, že uživatelský účet nemusí být dostupný nepřetržitě, a to zejména s ohledem na nutnou
          údržbu hardwarového a softwarového vybavení prodávajícího, popř. nutnou údržbu hardwarového a softwarového
          vybavení třetích osob.
        </Subsubsection>
      </Subsection>
      <Subsection name="3. UZAVŘENÍ KUPNÍ SMLOUVY">
        <Subsubsection number="3.1">
          Veškerá prezentace zboží umístěná ve webovém rozhraní obchodu je informativního charakteru a prodávající není
          povinen uzavřít kupní smlouvu ohledně tohoto zboží. Ustanovení § 1732 odst. 2 občanského zákoníku se
          nepoužije.
        </Subsubsection>
        <Subsubsection number="3.2">
          Webové rozhraní obchodu obsahuje informace o zboží, a to včetně uvedení cen jednotlivého zboží a nákladů za
          navrácení zboží, jestliže toto zboží ze své podstaty nemůže být navráceno obvyklou poštovní cestou. Ceny zboží
          jsou uvedeny včetně daně z přidané hodnoty a všech souvisejících poplatků. Ceny zboží zůstávají v platnosti po
          dobu, kdy jsou zobrazovány ve webovém rozhraní obchodu. Tímto ustanovením není omezena možnost prodávajícího
          uzavřít kupní smlouvu za individuálně sjednaných podmínek.
        </Subsubsection>
        <Subsubsection number="3.3">
          Webové rozhraní obchodu obsahuje také informace o nákladech spojených s balením a dodáním zboží. Informace o
          nákladech spojených s balením a dodáním zboží uvedené ve webovém rozhraní obchodu platí pouze v případech, kdy
          je zboží doručováno v rámci území České republiky.
        </Subsubsection>
        <Subsubsection number="3.4">
          Pro objednání zboží vyplní kupující objednávkový formulář ve webovém rozhraní obchodu. Objednávkový formulář
          obsahuje zejména informace o:
          <Subsubsection number="3.4.1">
            objednávaném zboží (objednávané zboží „vloží“ kupující do elektronického nákupního košíku webového rozhraní
            obchodu),{' '}
          </Subsubsection>
          <Subsubsection number="3.4.2">
            způsobu úhrady kupní ceny zboží, údaje o požadovaném způsobu doručení objednávaného zboží a{' '}
          </Subsubsection>
          <Subsubsection number="3.4.3">
            informace o nákladech spojených s dodáním zboží (dále společně jen jako „objednávka“).
          </Subsubsection>
        </Subsubsection>
        <Subsubsection number="3.5">
          Před zasláním objednávky prodávajícímu je kupujícímu umožněno zkontrolovat a měnit údaje, které do objednávky
          kupující vložil, a to i s ohledem na možnost kupujícího zjišťovat a opravovat chyby vzniklé při zadávání dat
          do objednávky. Objednávku odešle kupující prodávajícímu kliknutím na tlačítko „Potvrdit nákup“. Údaje uvedené
          v objednávce jsou prodávajícím považovány za správné. Prodávající neprodleně po obdržení objednávky toto
          obdržení kupujícímu potvrdí elektronickou poštou, a to na adresu elektronické pošty kupujícího uvedenou v
          uživatelském účtu či v objednávce (dále jen „elektronická adresa kupujícího“).
        </Subsubsection>
        <Subsubsection number="3.6">
          Prodávající je vždy oprávněn v závislosti na charakteru objednávky (množství zboží, výše kupní ceny,
          předpokládané náklady na dopravu) požádat kupujícího o dodatečné potvrzení objednávky (například písemně či
          telefonicky).
        </Subsubsection>
        <Subsubsection number="3.7">
          Smluvní vztah mezi prodávajícím a kupujícím vzniká doručením přijetí objednávky (akceptací), jež je
          prodávajícím zasláno kupujícímu elektronickou poštou, a to na adresu elektronické pošty kupujícího.
        </Subsubsection>
        <Subsubsection number="3.8">
          Kupující souhlasí s použitím komunikačních prostředků na dálku při uzavírání kupní smlouvy. Náklady vzniklé
          kupujícímu při použití komunikačních prostředků na dálku v souvislosti s uzavřením kupní smlouvy (náklady na
          internetové připojení, náklady na telefonní hovory) si hradí kupující sám, přičemž tyto náklady se neliší od
          základní sazby.
        </Subsubsection>
        <Subsubsection number="3.9">
          Na e-shopu se poskytuje přístup k hodnocení zboží jinými spotřebiteli. Autenticita těchto recenzí je
          zajišťována tím, že propojujeme hodnocení s konkrétními objednávkami, tudíž není možné zboží hodnotit bez
          provedení objednávky. Tímto způsobem jsme schopni ověřit a prokázat, že recenze pochází od reálného
          spotřebitele.
        </Subsubsection>
      </Subsection>
      <Subsection name="4. CENA ZBOŽÍ A PLATEBNÍ PODMÍNKY">
        <Subsubsection number="4.1">
          Cenu zboží a případné náklady spojené s dodáním zboží dle kupní smlouvy může kupující uhradit prodávajícímu
          pouze bezhotovostně platební kartou prostřednictví systému Comgate.
        </Subsubsection>
        <Subsubsection number="4.2">
          Společně s kupní cenou je kupující povinen zaplatit prodávajícímu také náklady spojené s balením a dodáním
          zboží ve smluvené výši. Není-li uvedeno výslovně jinak, rozumí se dále kupní cenou i náklady spojené s dodáním
          zboží.
        </Subsubsection>
        <Subsubsection number="4.3">
          Prodávající nepožaduje od kupujícího zálohu či jinou obdobnou platbu. Tímto není dotčeno ustanovení čl. 4.6
          obchodních podmínek ohledně povinnosti uhradit kupní cenu zboží předem.
        </Subsubsection>
        <Subsubsection number="4.4">
          V případě bezhotovostní platby je kupní cena splatná do 5 dnů od uzavření kupní smlouvy.
        </Subsubsection>
        <Subsubsection number="4.5">
          V případě bezhotovostní platby je kupující povinen uhrazovat kupní cenu zboží společně s uvedením variabilního
          symbolu platby. V případě bezhotovostní platby je závazek kupujícího uhradit kupní cenu splněn okamžikem
          připsání příslušné částky na účet prodávajícího.
        </Subsubsection>
        <Subsubsection number="4.6">
          Prodávající je oprávněn, zejména v případě, že ze strany kupujícího nedojde k dodatečnému potvrzení objednávky
          (čl. 3.6), požadovat uhrazení celé kupní ceny ještě před odesláním zboží kupujícímu. Ustanovení § 2119 odst. 1
          občanského zákoníku se nepoužije.
        </Subsubsection>
        <Subsubsection number="4.7">
          Případné slevy z ceny zboží poskytnuté prodávajícím kupujícímu nelze vzájemně kombinovat.
        </Subsubsection>
        <Subsubsection number="4.8">
          Je-li to v obchodním styku obvyklé nebo je-li tak stanoveno obecně závaznými právními předpisy, vystaví
          prodávající ohledně plateb prováděných na základě kupní smlouvy kupujícímu daňový doklad - fakturu.
          Prodávající je plátcem daně z přidané hodnoty. Daňový doklad - fakturu vystaví prodávající kupujícímu po
          uhrazení ceny zboží a zašle jej v elektronické podobě na elektronickou adresu kupujícího.
        </Subsubsection>
        <Subsubsection number="4.9">
          Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu účtenku. Zároveň je povinen
          zaevidovat přijatou tržbu u správce daně online; v případě technického výpadku pak nejpozději do 48 hodin.
        </Subsubsection>
      </Subsection>
      <Subsection name="5. ODSTOUPENÍ OD KUPNÍ SMLOUVY" id={CONTRACT_WITHDRAWAL_ID}>
        <Subsubsection number="5.1">
          Kupující bere na vědomí, že dle ustanovení § 1837 občanského zákoníku nelze mimo jiné odstoupit od kupní
          smlouvy o dodávce zboží, které bylo upraveno podle přání kupujícího nebo pro jeho osobu, od kupní smlouvy o
          dodávce zboží, které podléhá rychlé zkáze, jakož i zboží, které bylo po dodání nenávratně smíseno s jiným
          zbožím, od kupní smlouvy o dodávce zboží v uzavřeném obalu, které spotřebitel z obalu vyňal a z hygienických
          důvodů jej není možné vrátit a od kupní smlouvy o dodávce zvukové nebo obrazové nahrávky nebo počítačového
          programu, pokud porušil jejich původní obal.
        </Subsubsection>
        <Subsubsection number="5.2">
          Nejedná-li se o případ uvedený v čl. 5.1 obchodních podmínek či o jiný případ, kdy nelze od kupní smlouvy
          odstoupit, má kupující v souladu s ustanovením § 1829 odst. 1 občanského zákoníku právo od kupní smlouvy
          odstoupit, a to do čtrnácti (14) dnů od převzetí zboží, přičemž v případě, že předmětem kupní smlouvy je
          několik druhů zboží nebo dodání několika částí, běží tato lhůta ode dne převzetí poslední dodávky zboží.
          Odstoupení od kupní smlouvy musí být prodávajícímu odesláno ve lhůtě uvedené v předchozí větě. Pro odstoupení
          od kupní smlouvy může kupující využit vzorový formulář poskytovaný prodávajícím, jenž tvoří přílohu obchodních
          podmínek. Odstoupení od kupní smlouvy může kupující zasílat mimo jiné na adresu provozovny prodávajícího či na
          adresu elektronické pošty prodávajícího{' '}
          <Link navOpts={{ to: 'mailto:' + CONTACT.EMAIL }}>{CONTACT.EMAIL}</Link>.
        </Subsubsection>
        <Subsubsection number="5.3">
          V případě odstoupení od kupní smlouvy dle čl. 5.2 obchodních podmínek se kupní smlouva od počátku ruší. Zboží
          musí být kupujícím prodávajícímu vráceno do čtrnácti (14) dnů od doručení odstoupení od kupní smlouvy
          prodávajícímu. Odstoupí-li kupující od kupní smlouvy, nese kupující náklady spojené s navrácením zboží
          prodávajícímu, a to i v tom případě, kdy zboží nemůže být vráceno pro svou povahu obvyklou poštovní cestou.
        </Subsubsection>
        <Subsubsection number="5.4">
          V případě odstoupení od kupní smlouvy dle čl. 5.2 obchodních podmínek vrátí prodávající peněžní prostředky
          přijaté od kupujícího do čtrnácti (14) dnů od odstoupení od kupní smlouvy kupujícím, a to stejným způsobem,
          jakým je prodávající od kupujícího přijal. Prodávající je taktéž oprávněn vrátit plnění poskytnuté kupujícím
          již při vrácení zboží kupujícím či jiným způsobem, pokud s tím kupující bude souhlasit a nevzniknou tím
          kupujícímu další náklady. Odstoupí-li kupující od kupní smlouvy, prodávající není povinen vrátit přijaté
          peněžní prostředky kupujícímu dříve, než mu kupující zboží vrátí nebo prokáže, že zboží prodávajícímu odeslal.{' '}
        </Subsubsection>
        <Subsubsection number="5.5">
          Nárok na úhradu škody vzniklé na zboží je prodávající oprávněn jednostranně započíst proti nároku kupujícího
          na vrácení kupní ceny.
        </Subsubsection>
        <Subsubsection number="5.6">
          V případech, kdy má kupující v souladu s ustanovením § 1829 odst. 1 občanského zákoníku právo od kupní smlouvy
          odstoupit, je prodávající také oprávněn kdykoliv od kupní smlouvy odstoupit, a to až do doby převzetí zboží
          kupujícím. V takovém případě vrátí prodávající kupujícímu kupní cenu bez zbytečného odkladu, a to
          bezhotovostně na účet určený kupujícím.
        </Subsubsection>
        <Subsubsection number="5.7">
          Je-li společně se zbožím poskytnut kupujícímu dárek, je darovací smlouva mezi prodávajícím a kupujícím
          uzavřena s rozvazovací podmínkou, že dojde-li k odstoupení od kupní smlouvy kupujícím, pozbývá darovací
          smlouva ohledně takového dárku účinnosti a kupující je povinen spolu se zbožím prodávajícímu vrátit i
          poskytnutý dárek.
        </Subsubsection>
      </Subsection>
      <Subsection name="6. PŘEPRAVA A DODÁNÍ ZBOŽÍ">
        <Subsubsection number="6.1">
          V případě, že je způsob dopravy smluven na základě zvláštního požadavku kupujícího, nese kupující riziko a
          případné dodatečné náklady spojené s tímto způsobem dopravy.
        </Subsubsection>
        <Subsubsection number="6.2">
          Je-li prodávající podle kupní smlouvy povinen dodat zboží na místo určené kupujícím v objednávce, je kupující
          povinen převzít zboží při dodání.
        </Subsubsection>
        <Subsubsection number="6.3">
          V případě, že je z důvodů na straně kupujícího nutno zboží doručovat opakovaně nebo jiným způsobem, než bylo
          uvedeno v objednávce, je kupující povinen uhradit náklady spojené s opakovaným doručováním zboží, resp.
          náklady spojené s jiným způsobem doručení.
        </Subsubsection>
        <Subsubsection number="6.4">
          Při převzetí zboží od přepravce je kupující povinen zkontrolovat neporušenost obalů zboží a v případě
          jakýchkoliv závad toto neprodleně oznámit přepravci. V případě shledání porušení obalu svědčícího o
          neoprávněném vniknutí do zásilky nemusí kupující zásilku od přepravce převzít.
        </Subsubsection>
        <Subsubsection number="6.5">
          Další práva a povinnosti stran při přepravě zboží mohou upravit zvláštní dodací podmínky prodávajícího,
          jsou-li prodávajícím vydány.
        </Subsubsection>
      </Subsection>
      <Subsection name="7. PRÁVA Z VADNÉHO PLNĚNÍ">
        <Subsubsection number="7.1">
          Práva a povinnosti smluvních stran ohledně práv z vadného plnění se řídí příslušnými obecně závaznými právními
          předpisy (zejména ustanoveními § 1914 až 1925, § 2099 až 2117 a § 2161 až 2174 občanského zákoníku a zákonem
          č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů).
        </Subsubsection>
        <Subsubsection number="7.2">
          Prodávající odpovídá kupujícímu, že zboží při převzetí nemá vady. Zejména prodávající odpovídá kupujícímu, že
          v době, kdy kupující zboží převzal:
          <Subsubsection number="7.2.1">
            má zboží vlastnosti, které si strany ujednaly, a chybí-li ujednání, má takové vlastnosti, které prodávající
            nebo výrobce popsal nebo které kupující očekával s ohledem na povahu zboží a na základě reklamy jimi
            prováděné,
          </Subsubsection>
          <Subsubsection number="7.2.2">
            se zboží hodí k účelu, který pro jeho použití prodávající uvádí nebo ke kterému se zboží tohoto druhu
            obvykle používá,
          </Subsubsection>
          <Subsubsection number="7.2.3">
            zboží odpovídá jakostí nebo provedením smluvenému vzorku nebo předloze, byla-li jakost nebo provedení určeno
            podle smluveného vzorku nebo předlohy,
          </Subsubsection>
          <Subsubsection number="7.2.4">je zboží v odpovídajícím množství, míře nebo hmotnosti a</Subsubsection>
          <Subsubsection number="7.2.5">zboží vyhovuje požadavkům právních předpisů.</Subsubsection>
        </Subsubsection>
        <Subsubsection number="7.3">
          Ustanovení uvedená v čl. 7.2 obchodních podmínek se nepoužijí u zboží prodávaného za nižší cenu na vadu, pro
          kterou byla nižší cena ujednána, na opotřebení zboží způsobené jeho obvyklým užíváním, u použitého zboží na
          vadu odpovídající míře používání nebo opotřebení, kterou zboží mělo při převzetí kupujícím, nebo vyplývá-li to
          z povahy zboží.
        </Subsubsection>
        <Subsubsection number="7.4">
          Projeví-li se vada v průběhu šesti měsíců od převzetí, má se za to, že zboží bylo vadné již při převzetí.
          Kupující je oprávněn uplatnit právo z vady, která se vyskytne u spotřebního zboží v době dvaceti čtyř měsíců
          od převzetí.
        </Subsubsection>
        <Subsubsection number="7.5">
          Práva z vadného plnění uplatňuje kupující u prodávajícího na adrese jeho provozovny, v níž je přijetí
          reklamace možné s ohledem na sortiment prodávaného zboží, případně i v sídle nebo místě podnikání.
        </Subsubsection>
        <Subsubsection number="7.6">
          Další práva a povinnosti stran související s odpovědností prodávajícího za vady může upravit reklamační řád
          prodávajícího.
        </Subsubsection>
      </Subsection>
      <Subsection name="8. DALŠÍ PRÁVA A POVINNOSTI SMLUVNÍCH STRAN">
        <Subsubsection number="8.1">
          Kupující nabývá vlastnictví ke zboží zaplacením celé kupní ceny zboží.
        </Subsubsection>
        <Subsubsection number="8.2">
          Prodávající není ve vztahu ke kupujícímu vázán žádnými kodexy chování ve smyslu ustanovení § 1826 odst. 1
          písm. e) občanského zákoníku.
        </Subsubsection>
        <Subsubsection number="8.3">
          Vyřizování stížností spotřebitelů zajišťuje prodávající prostřednictvím elektronické adresy{' '}
          <Link navOpts={{ to: 'mailto:' + CONTACT.EMAIL }}>{CONTACT.EMAIL}</Link>. Informaci o vyřízení stížnosti
          kupujícího zašle prodávající na elektronickou adresu kupujícího.
        </Subsubsection>
        <Subsubsection number="8.4">
          K mimosoudnímu řešení spotřebitelských sporů z kupní smlouvy je příslušná Česká obchodní inspekce, se sídlem
          Štěpánská 567/15, 120 00 Praha 2, IČO: 000 20 869, internetová adresa:{' '}
          <Link navOpts={{ to: 'https://adr.coi.cz/cs' }}>https://adr.coi.cz/cs</Link>. Platformu pro řešení sporů
          on-line nacházející se na internetové adrese{' '}
          <Link navOpts={{ to: 'http://ec.europa.eu/consumers/odr' }}>http://ec.europa.eu/consumers/odr</Link> je možné
          využít při řešení sporů mezi prodávajícím a kupujícím z kupní smlouvy.
        </Subsubsection>
        <Subsubsection number="8.5">
          Evropské spotřebitelské centrum Česká republika, se sídlem Štěpánská 567/15, 120 00 Praha 2, internetová
          adresa: <Link navOpts={{ to: 'http://www.evropskyspotrebitel.cz' }}>http://www.evropskyspotrebitel.cz</Link>{' '}
          je kontaktním místem podle Nařízení Evropského parlamentu a Rady (EU) č. 524/2013 ze dne 21. května 2013 o
          řešení spotřebitelských sporů on-line a o změně nařízení (ES) č. 2006/2004 a směrnice 2009/22/ES (nařízení o
          řešení spotřebitelských sporů on-line).
        </Subsubsection>
        <Subsubsection number="8.6">
          Prodávající je oprávněn k prodeji zboží na základě živnostenského oprávnění. Živnostenskou kontrolu provádí v
          rámci své působnosti příslušný živnostenský úřad. Dozor nad oblastí ochrany osobních údajů vykonává Úřad pro
          ochranu osobních údajů. Česká obchodní inspekce vykonává ve vymezeném rozsahu mimo jiné dozor nad dodržováním
          zákona č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů.
        </Subsubsection>
        <Subsubsection number="8.7">
          Kupující tímto přebírá na sebe nebezpečí změny okolností ve smyslu § 1765 odst. 2 občanského zákoníku.
        </Subsubsection>
      </Subsection>
      <Subsection name="9. OCHRANA OSOBNÍCH ÚDAJŮ">
        <Subsubsection number="9.1">
          Svou informační povinnost vůči kupujícímu ve smyslu čl. 13 Nařízení Evropského parlamentu a Rady 2016/679 o
          ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení
          směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (dále jen „nařízení GDPR“) související se
          zpracováním osobních údajů kupujícího pro účely plnění kupní smlouvy, pro účely jednání o této smlouvě a pro
          účely plnění veřejnoprávních povinností prodávajícího plní prodávající prostřednictvím zvláštního dokumentu.
        </Subsubsection>
      </Subsection>
      <Subsection name="10. ZASÍLÁNÍ OBCHODNÍCH SDĚLENÍ A UKLÁDÁNÍ COOKIES">
        <Subsubsection number="10.1">
          Kupující souhlasí se zasíláním informací souvisejících se zbožím, službami nebo podnikem prodávajícího na
          elektronickou adresu kupujícího a dále souhlasí se zasíláním obchodních sdělení prodávajícím na elektronickou
          adresu kupujícího. Svou informační povinnost vůči kupujícímu ve smyslu čl. 13 nařízení GDPR související se
          zpracováním osobních údajů kupujícího pro účely zasílání obchodních sdělení plní prodávající prostřednictvím
          zvláštního dokumentu.
        </Subsubsection>
        <Subsubsection number="10.2">
          Kupující souhlasí s ukládáním tzv. cookies na jeho počítač. V případě, že je nákup na webové stránce možné
          provést a závazky prodávajícího z kupní smlouvy plnit, aniž by docházelo k ukládání tzv. cookies na počítač
          kupujícího, může kupující souhlas podle předchozí věty kdykoliv odvolat.
        </Subsubsection>
      </Subsection>
      <Subsection name="11. DORUČOVÁNÍ">
        <Subsubsection number="11.1">Kupujícímu může být doručováno na elektronickou adresu kupujícího.</Subsubsection>
        <Subsubsection number="11.2">
          Za doručené se považuje i oznámení, jehož převzetí bylo adresátem odmítnuto, které nebylo vyzvednuto v úložní
          době, nebo které se vrátilo jako nedoručitelné.
        </Subsubsection>
        <Subsubsection number="11.3">
          Smluvní strany můžou běžnou korespondenci vzájemně doručovat prostřednictvím elektronické pošty, a to na
          adresu elektronické pošty uvedenou v uživatelském účtu kupujícího či uvedenou kupujícím v objednávce, resp. na
          adresu uvedenou na webové stránce prodávajícího.
        </Subsubsection>
      </Subsection>
      <Subsection name="12. ZÁVĚREČNÁ USTANOVENÍ">
        <Subsubsection number="12.1">
          Pokud vztah založený kupní smlouvou obsahuje mezinárodní (zahraniční) prvek, pak strany sjednávají, že vztah
          se řídí českým právem. Volbou práva podle předchozí věty není kupující, který je spotřebitelem, zbaven
          ochrany, kterou mu poskytují ustanovení právního řádu, od nichž se nelze smluvně odchýlit, a jež by se v
          případě neexistence volby práva jinak použila dle ustanovení čl. 6 odst. 1 Nařízení Evropského parlamentu a
          Rady (ES) č. 593/2008 ze dne 17. června 2008 o právu rozhodném pro smluvní závazkové vztahy (Řím I).
        </Subsubsection>
        <Subsubsection number="12.2">
          Je-li některé ustanovení obchodních podmínek neplatné nebo neúčinné, nebo se takovým stane, namísto neplatných
          ustanovení nastoupí ustanovení, jehož smysl se neplatnému ustanovení co nejvíce přibližuje. Neplatností nebo
          neúčinností jednoho ustanovení není dotčena platnost ostatních ustanovení.
        </Subsubsection>
        <Subsubsection number="12.3">
          Kupní smlouva včetně obchodních podmínek je archivována prodávajícím v elektronické podobě a není přístupná.
        </Subsubsection>
        <Subsubsection number="12.4">
          Přílohu obchodních podmínek tvoří vzorový formulář pro odstoupení od kupní smlouvy.
        </Subsubsection>
        <Subsubsection number="12.5">
          Kontaktní údaje prodávajícího: adresa pro doručování Maršovská 2239, 68801 Uherský Brod, Česká republika,
          adresa elektronické pošty <Link navOpts={{ to: 'mailto:' + CONTACT.EMAIL }}>{CONTACT.EMAIL}</Link>, telefon{' '}
          {CONTACT.PHONE}.
        </Subsubsection>
      </Subsection>
      V Uherském Brodě dne 1. 9. 2024
    </Section>
  )
})
