import { useCallback, useMemo } from 'react'
import Box from '@mui/material/Box'
import VideoCard from './VideoCard'
import VideoGroup from './VideoGroup'
import { useUser } from 'features/authentication'
import Text from 'components/Text'
import { naturalSort } from 'utils/sort'

export default function VideoCategory({ groupedVideos }) {
  const user = useUser()
  const compareVideoAccess = useCallback(
    (videoA, videoB) => {
      const hasAccessToVideoA = (user.isLoggedIn && user.hasAccessTo(videoA.id)) || !videoA.paid
      const hasAccessToVideoB = (user.isLoggedIn && user.hasAccessTo(videoB.id)) || !videoB.paid
      if (hasAccessToVideoA && !hasAccessToVideoB) {
        return -1
      }
      if (!hasAccessToVideoA && hasAccessToVideoB) {
        return 1
      }
      return 0
    },
    [user]
  )

  const isEmpty = useMemo(() => Object.keys(groupedVideos).length === 0, [groupedVideos])
  const sortedGroups = useMemo(
    () =>
      naturalSort(
        Object.entries(groupedVideos).map(([group, videos]) => ({
          group: group,
          videos: naturalSort(videos, ['groupOrder', 'name'])
        })),
        'group'
      ),
    // eslint-disable-next-line
    [groupedVideos, user.data]
  )

  return (
    <Box mt={2}>
      {isEmpty ?
        <Text variant="h6" color="warning.main" align="center" disableUserSelection>
          Tato kategorie zatím neobsahuje žádná videa
        </Text>
      : sortedGroups.map((item, index) => (
          <VideoGroup key={index} index={index} groupName={item.group}>
            {item.videos.sort(compareVideoAccess).map(video => (
              <VideoCard
                key={video.id}
                video={video}
                hasAccess={(user.isLoggedIn && user.hasAccessTo(video.id)) || !video.paid}
                availableUntil={user.isLoggedIn ? user.hasAccessUntil(video.id) : null}
              />
            ))}
          </VideoGroup>
        ))
      }
    </Box>
  )
}
