import { useState } from 'react'
import Box from '@mui/material/Box'
import CancelIcon from '@mui/icons-material/Cancel'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import { Portal } from '@mui/base/Portal'

import { Backdrop, BACKDROP_CONTENT_TYPE } from 'features/authentication'
import Subsection from 'components/Subsection'
import Section from 'components/Section'
import Text from 'components/Text'
import { SECTION_ID } from '../data/constants'
import Contact from './Contact'
import ContactForm from './ContactForm'
import FAQ from './FAQ'
import { Video } from 'features/video'
import Link from 'components/Link'
import { getCredits, useOffers } from 'pages/top-up-funds'
import { getCreditsString } from 'utils/string'

export default function MainPage() {
  const offers = useOffers()
  const [showSignInBackdrop, setShowSignInBackdrop] = useState(false)
  const handleSignInBackdropClose = () => setShowSignInBackdrop(false)
  const handleRegisterClick = () => setShowSignInBackdrop(true)

  return (
    <>
      {showSignInBackdrop && (
        <Portal container={document.getElementById('root')}>
          <Backdrop
            open={showSignInBackdrop}
            onClose={handleSignInBackdropClose}
            defaultContent={BACKDROP_CONTENT_TYPE.REGISTER}
          />
        </Portal>
      )}
      <Section id={SECTION_ID.INTRODUCTION} name="Doučování a Lektor Ondřej">
        <Subsection name="Rychlé představení">
          Ahoj, vítej na mých doučovacích stránkách! Následující video rychle shrnuje vše podstatné o mně a mém
          doučování. Pokud chceš podrobnější informace, jsou dostupné pod videem, nebo použij vyhledávač &#128522;.
        </Subsection>
        <Subsection name="Úvod">
          <Text>Ahoj! Jmenuji se Ondřej, mám rád hory, badminton a &hellip; doučuji matematiku! &#128522;</Text>
          <Text>
            Už na střední škole jsem se ve třídě držel mezi lepšími počtáři, na výšce jsem pomáhal spolužákům se zápočty
            nebo zkouškami, a nakonec jsem začal pracovat jako doučovatel matematiky.
          </Text>
          <Text>
            {' '}
            Úspěšně doučuji již déle než 2 roky a v matematice schopen efektivně pomoci téměř se vším, s čím se běžný
            student setká.{' '}
          </Text>
          <Text>
            Mám studenty pravidelné i nárazové, rychlejší i pomalejší, všem se však snažím připravit řešení na míru dle
            potřeb a ukázat, že matematika nemusí být velký strašák.
          </Text>
        </Subsection>
        <Subsection name="Smysl doučování">
          <Text>
            Doučování je často vnímáno jako nějaká slabost. Realita je však jiná. Probírané látky přibývá a studenti se
            mnohdy potýkají s velkým množstvím učiva. Někdy je toho prostě příliš, a navíc hodně narychlo, žáci
            přestanou zvládat tempo a problém je na světě. &#128577;
          </Text>
          <Text>
            Odpadlá hodina, nemoc studenta, či úpadek pozornosti v nesprávný moment jsou zabijáci matematiky, jelikož na
            dobře ukotvených předchozích znalostech tento předmět často staví.
          </Text>
          <Text>
            Smyslem mých doučování je pomoci studentům všech kategorií doplnit mezery vzniklé ve znalostech matematiky
            takovým způsobem, aby byli schopni dosáhnout svých cílů ať už to jsou přijímačky na SŠ/maturita/přijímačky
            na VŠ/zkouška na VŠ/nebo prostě jen příprava na test ve škole.
          </Text>
        </Subsection>
        <Subsection name="Metodika">
          <Text>Doučuji především online - tato forma je pod mým vedením efektivní a úsporná.</Text>
          <Text>
            Ke hlasovému spojení se studenty využívám Discord (obdoba Skype, Teams, &hellip;). Pro vizualizaci a hlavně
            spolupráci slouží online tabule, kde vše společně počítáme a vysvětlujeme.
          </Text>
          <Text>
            Ve svých hodinách se snažím nejprve odbourat strach z matematiky, poskytnout studentům záchytné body, a
            nakonec vybudovat žákům matematické sebevědomí pomocí jimi počítaných příkladů za mé asistence. Nikdy
            studenta nenechám na holičkách, ale zadarmo za něj nic nevypočítám. Tímto přístupem si studenti osvojí nutné
            znalosti a jsou schopni je nadále využívat.
          </Text>
          <Text>
            Zní to dobře? Podívejte se na můj přístup na{' '}
            <Link navOpts={{ hash: 'sample-video' }}>ukázkovém videu níže.</Link>
          </Text>
        </Subsection>
        <Subsection name="Ukázkové video" id="sample-video">
          <Box maxWidth={720} margin="auto">
            <Video videoName="sample.webm" posterName="sample.jpg" />
          </Box>
        </Subsection>
      </Section>
      <Section id={SECTION_ID.TUTORING} name="Co doučuji?">
        Pokud si nejste jistí, jestli zvládnu pomoci přímo s vaším problémem, ozvěte se mi na{' '}
        <Link navOpts={{ to: 'mailto:info@doucujimatematiku.cz' }}>info@doucujimatematiku.cz</Link> nebo se podívejte na
        SEZNAM TÉMAT TODO: link
      </Section>
      <Section id={SECTION_ID.TIPS} name="Forma spolupráce">
        <Subsection name="Online doučování">
          Přes <Link navOpts={{ hash: SECTION_ID.CONTACT_FORM }}>kontaktní formulář</Link> či{' '}
          <Link navOpts={{ to: 'mailto:info@doucujimatematiku.cz' }}>email</Link> se můžeš ozvat přímo mně a dohodnout
          si lekce, doporučuji si však nejdříve ověřit dostupnost v Kalendáři (odkaz)
        </Subsection>
        <Subsection name="Výuková videa">
          Pokud mám již plno, nebo ti nevyhovuje termín, můžeš zkusit má výuková videa v sekci{' '}
          <Link navOpts={{ to: 'video-library' }}>Videotéka</Link>. Videa jsou připravena tak, aby co nejvíce přiblížila
          lekci semnou a umožnila pochopit vybranou tematiku.{' '}
          <Link navOpts={{ hash: '' }} preventDefault={true} onClick={handleRegisterClick}>
            REGISTRUJ SE
          </Link>{' '}
          a společně to zvládneme!
        </Subsection>
      </Section>
      <Section id={SECTION_ID.PRICING} name="Ceník">
        <Subsection name="Online doučování">
          <Text gutterBottom>
            Cena za lekci je dohodnuta přes email na základě aktuální vytíženosti a náročnosti doučované látky.
          </Text>
        </Subsection>
        <Subsection name="Výuková videa">
          <Text gutterBottom>
            Po registraci si žák může zpřístupnit jednotlivá výuková videa za tzv. Kredity. Pro získání Kreditů je nutné
            zvolit jednu z dostupných variant níže, provést transakci
            <Text variant="span" fontStyle="italic">
              (kredity budou na Váš účet připsány ihned po obdržení platby)
            </Text>{' '}
            a studium může začít!
          </Text>
          {offers.isError && (
            <Stack direction="row" alignItems="center" color="error.main" ml={3} mt={1}>
              <CancelIcon sx={{ fontSize: 18, mr: 0.5 }} />
              <Text fontSize={12} fontWeight={500}>
                Chyba! Nepodařilo se načít nabídky.
              </Text>
            </Stack>
          )}
          {offers.isLoading && <Skeleton variant="rounded" height={85} width={160} sx={{ ml: 3, mt: 2 }} />}
          {offers.isSuccess && (
            <ol>
              {offers.data.map(offer => {
                const credits = getCredits(offer)
                return (
                  <li key={offer.id}>
                    <Text variant="span" fontWeight={500} ml={0.5}>
                      {offer.price}&nbsp;Kč
                      <Text variant="span" color="text.secondary" fontSize={11} ml={0.5}>
                        ({credits}&nbsp;{getCreditsString(credits)})
                      </Text>
                    </Text>
                  </li>
                )
              })}
            </ol>
          )}
        </Subsection>
      </Section>
      <Section id={SECTION_ID.CONTACT} name="Kontakt">
        <Contact />
        <Box id={SECTION_ID.CONTACT_FORM} pt={2} mt={2} mx="auto" maxWidth={400}>
          <Text mb={1} disableUserSelection color="text.secondary" fontWeight={500}>
            Kontaktní formulář
          </Text>
          <ContactForm />
        </Box>
      </Section>
      <Section id={SECTION_ID.FAQ} name="Často kladené dotazy">
        <FAQ />
      </Section>
    </>
  )
}
