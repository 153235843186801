import Box from '@mui/material/Box'
import CancelIcon from '@mui/icons-material/Cancel'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import Subsection from 'components/Subsection'
import Text from 'components/Text'
import usePaymentSummary from '../hooks/usePaymentSummary'
import { getCreditsString } from 'utils/string'

const Label = props => <Text fontSize={12} fontWeight={500} sx={{ width: 150 }} {...props} />

export default function Payments(props) {
  const summary = usePaymentSummary()

  return (
    <Subsection name="Souhrn plateb" {...props}>
      {summary.isError && (
        <Stack direction="row" alignItems="center" color="error.main">
          <CancelIcon sx={{ fontSize: 20, mr: 0.5 }} />
          <Text fontWeight={600}>Chyba! Nepodařilo se načíst informace o platbách.</Text>
        </Stack>
      )}
      {summary.isLoading && <Skeleton variant="rounded" width={240} height={62} />}
      {summary.isSuccess && (
        <Box width="fit-content">
          <Stack direction="row">
            <Label>Celkem vyděláno:</Label>
            <Text fontWeight={600} color={summary.data.moneyEarned ? 'success.main' : 'text.secondary'}>
              {summary.data.moneyEarned}&nbsp;Kč
            </Text>
          </Stack>
          <Stack direction="row">
            <Label>Celkový počet plateb:</Label>
            <Text fontWeight={600}>{summary.data.paymentsCount}</Text>
          </Stack>
          <Stack direction="row">
            <Label>Celkem vydáno kreditů:</Label>
            <Text fontWeight={600}>
              {summary.data.creditsIssued}&nbsp;{getCreditsString(summary.data.creditsIssued)}
            </Text>
          </Stack>
        </Box>
      )}
    </Subsection>
  )
}
