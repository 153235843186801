import { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import { createPayment } from 'services/firebase'
import Text from 'components/Text'
import useSessionStorage from 'hooks/useSessionStorage'
import { SELECTED_OFFER_STORAGE_KEY } from '../data/constants'
import { Button } from '@mui/material'

export default function Payment({ onError }) {
  const [offerID, setOfferID] = useSessionStorage(SELECTED_OFFER_STORAGE_KEY, null)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (offerID === null) {
      onError && onError()
    } else {
      createPayment({ offerID: offerID })
        .then(response => {
          console.log(response)
          // reset the offer selection
          setOfferID(null)
          // redirect to the paywall
          console.info('Redirecting to Comgate paywall.')
          window.location.href = response.data.redirect
        })
        .catch(error => {
          console.error('Payment error:', error.message)
          setIsError(true)
        })
    }
    // eslint-disable-next-line
  }, [])

  return isError ?
      <Stack alignItems="center" spacing={2}>
        <Stack direction="row" justifyContent="center">
          <CancelIcon color="error" sx={{ fontSize: 30, mr: 1 }} />
          <Text color="error.main" fontSize={18} disableUserSelection align="center">
            Chyba! Nepodařilo se přesměrovat na platební bránu.
          </Text>
        </Stack>
        <Button color="inherit" onClick={onError}>
          Zpět na výběr nabíky
        </Button>
      </Stack>
    : <Stack direction="row" justifyContent="center">
        <CircularProgress color="inherit" size={30} sx={{ mr: 1.5 }} />
        <Text align="center" variant="h6">
          Probíhá přesměrování na platební bránu
        </Text>
      </Stack>
}
