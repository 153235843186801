import { lazy, memo, Suspense } from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Link from 'components/Link'
import Text from 'components/Text'
import { MAIN_PAGE_SECTION_ID } from 'pages'

// lazy-loaded components
const Logo = lazy(() => import('components/Logo'))

export default memo(function LogoLink({ logoSize, display, children, ...props }) {
  return (
    <Stack direction="row" alignItems="center" display={display} {...props}>
      <Link
        navOpts={{ to: '/', hash: MAIN_PAGE_SECTION_ID.HOME }}
        sx={{
          display: 'inline-flex',
          padding: '0 8px',
          height: '100%',
          alignItems: 'center'
        }}
      >
        <Suspense fallback={<Skeleton variant="rectangular" width={logoSize} height={logoSize} />}>
          <Logo size={logoSize} />
        </Suspense>
      </Link>
      <Text variant="h5" noWrap component="span" fontWeight={700} disableUserSelection>
        {children}
      </Text>
    </Stack>
  )
})
