import ReactDOM from 'react-dom/client'
import { StrictMode } from 'react'
import { RouterProvider } from 'router'
import reportWebVitals from './reportWebVitals'
import 'assets/global.css'

// TODO: use lazy loading
// TODO: refactor the code
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <RouterProvider />
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
