import MuiTooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'

export default function Tooltip({
  text,
  placement = 'bottom',
  arrow = false,
  offsetX = 0,
  offsetY = 0,
  children,
  ...props
}) {
  return (
    <MuiTooltip
      placement={placement}
      arrow={arrow}
      TransitionComponent={Zoom}
      title={text}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [offsetX, offsetY]
            }
          }
        ]
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  )
}
