import { useTheme } from '@mui/material'
import Link from '@mui/material/Link'

import appleIcon from 'assets/apple.png'
import appleDarkIcon from 'assets/apple_dark.png'
import facebookIcon from 'assets/facebook.png'
import googleIcon from 'assets/google.png'
import xIcon from 'assets/twitter.png'
import xDarkIcon from 'assets/twitter_dark.png'

export const SOCIAL_BUTTON_TYPE = {
  APPLE: 'apple',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  X: 'x'
}

export default function SocialButton({ type, onClick, ...props }) {
  let src = null
  const theme = useTheme()
  switch (type) {
    case SOCIAL_BUTTON_TYPE.APPLE:
      src = theme.palette.mode === 'dark' ? appleDarkIcon : appleIcon
      break
    case SOCIAL_BUTTON_TYPE.FACEBOOK:
      src = facebookIcon
      break
    case SOCIAL_BUTTON_TYPE.GOOGLE:
      src = googleIcon
      break
    case SOCIAL_BUTTON_TYPE.X:
      src = theme.palette.mode === 'dark' ? xDarkIcon : xIcon
      break
    default:
  }

  return (
    <Link onClick={onClick} href="#">
      <img height={48} width={48} alt="" src={src} />
    </Link>
  )
}
