import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Text from 'components/Text'
import { usePosterURL } from 'features/video'
import { getCreditsString } from 'utils/string'

export default function VideoBanner({ poster, videoName, price }) {
  const posterURL = usePosterURL(poster)
  const lowestPrice = Math.min(price.day, price.week, price.month)
  return (
    <Box height={1} position="relative">
      {posterURL.isLoading && <Skeleton variant="rectangle" height="100%" />}
      {posterURL.isSuccess && (
        <Box
          sx={{
            backgroundColor: 'black',
            '& img': { opacity: 0.5, display: 'block' }
          }}
        >
          <img src={posterURL.data} alt={videoName} />
        </Box>
      )}
      <Box
        px={2}
        py={1}
        top={0}
        right={0}
        position="absolute"
        sx={{
          color: 'white',
          textAlign: 'center',
          backgroundColor: 'success.main'
        }}
      >
        <Text disableUserSelection fontWeight={600}>
          od {lowestPrice} {getCreditsString(lowestPrice)}
        </Text>
      </Box>
    </Box>
  )
}
