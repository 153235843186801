import Typography from '@mui/material/Typography'

export default function Text({
  fontSize = 'inherit',
  fontWeight = 'inherit',
  fontStyle = 'inherit',
  fontFamily = 'inherit',
  noWrap = false,
  disableUserSelection = false,
  sx,
  ...props
}) {
  return (
    <Typography
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
      fontFamily={fontFamily}
      noWrap={noWrap}
      sx={{ userSelect: disableUserSelection ? 'none' : 'auto', ...sx }}
      {...props}
    />
  )
}
