export function getCurrentTimestamp() {
  return Date.now()
}

export function getOffsetTimestamp(seconds) {
  return getCurrentTimestamp() + seconds * 1000
}

export function getCzechDateFormat(timestamp, withTime = false) {
  const dateObj = new Date(timestamp)
  const date = dateObj.toLocaleDateString('cs-CZ', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
  const time = dateObj.toLocaleTimeString('cs-Cz', {
    hour: 'numeric',
    minute: 'numeric'
  })

  return date + (withTime ? `, ${time}` : '')
}
