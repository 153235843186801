import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Text from 'components/Text'
import profilePicture from 'assets/kucci_profile_picture.jpg'
import SocialMediaLink from './SocialMediaLink'
import { CONTACT } from 'data/constants'

// icons
import EmailIcon from '@mui/icons-material/Email'

const AVATAR_SIZE_XS = 100
const AVATAR_SIZE_MD = 128
export default function Contact() {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <Avatar
        alt="Ondřej Kučera"
        sx={{ width: { xs: AVATAR_SIZE_XS, md: AVATAR_SIZE_MD }, height: { xs: AVATAR_SIZE_XS, md: AVATAR_SIZE_MD } }}
        src={profilePicture}
      />
      <Stack spacing={1}>
        <Text variant="h5" fontWeight={500} fontSize={{ xs: 18, md: 24 }}>
          Ondřej Kučera
        </Text>
        <Stack spacing={0.5}>
          <SocialMediaLink Icon={EmailIcon} iconColor="#FFA500" href={`mailto:${CONTACT.EMAIL}`}>
            {CONTACT.EMAIL}
          </SocialMediaLink>
        </Stack>
      </Stack>
    </Stack>
  )
}
