import { memo, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import { Portal } from '@mui/base/Portal'
import { Backdrop, useUser } from 'features/authentication'
import { DropdownMenu, DropdownMenuItem } from 'components/dropdown-menu'
import RoundIconButton from 'components/RoundIconButton'
import Text from 'components/Text'
import Tooltip from 'components/Tooltip'
import Notification from 'components/Notification'
import { UploadVideoBackdrop } from 'features/video'
import { getCreditsString } from 'utils/string'

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import PersonIcon from '@mui/icons-material/Person'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import VideoFileIcon from '@mui/icons-material/VideoFile'

export default memo(function AccountMenu(props) {
  const user = useUser()
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [showUploadBackdrop, setShowUploadBackdrop] = useState(false)
  const [showUploadSuccessNotification, setShowUploadSuccessNotification] = useState(false)
  const isOpen = Boolean(menuAnchor)
  const menuID = 'account-dropdown-menu'
  const handleMenuClose = () => setMenuAnchor(null)
  const handleBackdropClose = () => setShowBackdrop(false)
  const handleVideoBackdropClose = () => setShowUploadBackdrop(false)
  const handleLogOut = () => user.logout()

  const handleAccountClick = event => {
    if (user.isLoggedIn) {
      setMenuAnchor(event.currentTarget)
    } else {
      setShowBackdrop(true)
      handleMenuClose()
    }
  }

  const handleUploadVideoClick = () => {
    handleMenuClose()
    setShowUploadBackdrop(true)
  }

  const nameToInitials = name => {
    if (typeof name === 'string' && name.length > 0) {
      const initials = name
        .split(' ')
        .map(s => s.substring(0, 1))
        .join('')
      if (initials.length >= 1 && initials.length <= 3) {
        return initials
      }
    }
    return <PersonIcon fontSize="large" />
  }

  return (
    <>
      {showBackdrop && (
        <Portal container={document.getElementById('root')}>
          <Backdrop open={showBackdrop} onClose={handleBackdropClose} />
        </Portal>
      )}
      {showUploadBackdrop && (
        <Portal container={document.getElementById('root')}>
          <UploadVideoBackdrop
            open={showUploadBackdrop}
            onClose={handleVideoBackdropClose}
            onSuccess={() => setShowUploadSuccessNotification(true)}
          />
        </Portal>
      )}
      <Notification
        severity="success"
        hideAfter={2000}
        open={showUploadSuccessNotification}
        onClose={() => setShowUploadSuccessNotification(false)}
      >
        Video bylo úspěšně nahráno.
      </Notification>
      <Tooltip
        text={
          user.isLoading ? null
          : user.isLoggedIn ?
            'Uživatelský účet'
          : 'Přihlásit se'
        }
        arrow
      >
        <span>
          <RoundIconButton
            loading={user.isLoading}
            onClick={handleAccountClick}
            aria-haspopup="true"
            aria-controls={menuID}
            aria-expanded={isOpen}
            Icon={user.isLoggedIn ? AccountCircleIcon : LoginIcon}
            {...props}
          />
        </span>
      </Tooltip>
      {user.isLoggedIn && (
        <DropdownMenu id={menuID} anchorEl={menuAnchor} open={isOpen} onClose={handleMenuClose} position="bottom-right">
          <ListItem sx={{ userSelect: 'none' }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                src={user.data.profilePicture}
                sx={{ width: 50, height: 50 }}
                alt={user.data.displayName ?? user.data.email}
              >
                {nameToInitials(user.data.displayName)}
              </Avatar>
              <Stack>
                {user.data.displayName && (
                  <Text variant="span" fontSize={14} fontWeight={500}>
                    {user.data.displayName}
                  </Text>
                )}
                <Text variant="span" fontSize={12} color="text.secondary">
                  {user.data.email}
                </Text>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <AccountBalanceIcon
                    sx={{ color: user.data.wallet === 0 ? 'error.main' : 'primary.dark', fontSize: 15 }}
                  />
                  <Text
                    variant="span"
                    fontSize={12}
                    fontWeight={500}
                    color={user.data.wallet === 0 ? 'error.main' : 'primary.dark'}
                  >
                    {user.data.wallet} {getCreditsString(user.data.wallet)}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </ListItem>
          <Divider variant="middle" sx={{ my: 1 }} />
          {(user.data.isAdmin || user.data.isDeveloper) && (
            <DropdownMenuItem
              text="Administrace"
              Icon={AdminPanelSettingsIcon}
              onClick={handleMenuClose}
              linkComponent
              linkProps={{ navOpts: { to: '/administration' }, textColor: true, sx: { fontWeight: 400 } }}
            />
          )}
          {(user.data.isAdmin || user.data.isDeveloper) && (
            <DropdownMenuItem text="Nahrát video" Icon={CloudUploadIcon} onClick={handleUploadVideoClick} />
          )}
          {(user.data.isAdmin || user.data.isDeveloper) && <Divider variant="middle" sx={{ my: 1 }} />}
          <DropdownMenuItem
            text="Dostupná videa"
            Icon={VideoFileIcon}
            onClick={handleMenuClose}
            linkComponent
            linkProps={{
              navOpts: { to: '/video-library' },
              textColor: true,
              sx: { fontWeight: 400 }
            }}
          />
          <DropdownMenuItem
            text="Dobít kredit"
            Icon={ShoppingCartIcon}
            onClick={handleMenuClose}
            linkComponent
            linkProps={{ navOpts: { to: '/top-up-funds' }, textColor: true, sx: { fontWeight: 400 } }}
          />
          <DropdownMenuItem
            text="Historie transakcí"
            Icon={AccountBalanceIcon}
            onClick={handleMenuClose}
            linkComponent
            linkProps={{ navOpts: { to: '/balance-history' }, textColor: true, sx: { fontWeight: 400 } }}
          />
          <Divider variant="middle" sx={{ my: 1 }} />
          <DropdownMenuItem text="Odhlásit se" Icon={LogoutIcon} onClick={handleLogOut} />
        </DropdownMenu>
      )}
    </>
  )
})
