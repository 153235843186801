import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { Footer } from 'features/footer'
import FooterLayout from './FooterLayout'
import { SECTION_ID } from 'pages/main/data/constants'

export default function ContentLayout(props) {
  return (
    <Stack component="main" flexGrow={1} overflow="auto" sx={{ scrollBehavior: 'smooth' }}>
      <Container id={SECTION_ID.HOME} maxWidth="lg" sx={{ flexGrow: 1, py: 2 }} {...props} />
      <FooterLayout>
        <Footer />
      </FooterLayout>
    </Stack>
  )
}
