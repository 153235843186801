import { useTheme } from '@emotion/react'
import Menu from '@mui/material/Menu'
import Zoom from '@mui/material/Zoom'
import Grow from '@mui/material/Grow'

// TODO: support additional positions: https://mui.com/material-ui/react-popover/#anchor-playground
export default function DropdownMenu({
  autoFocus = false,
  noPadding = false,
  position = 'bottom-left',
  arrow = false,
  children,
  ...props
}) {
  // default bottom - left position
  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
  }
  const transformOrigin = {
    vertical: 'top',
    horizontal: 'left'
  }

  switch (position) {
    case 'bottom-right':
      anchorOrigin.horizontal = 'right'
      transformOrigin.horizontal = 'right'
      break
    case 'right-center':
      anchorOrigin.vertical = 'center'
      anchorOrigin.horizontal = 'right'
      transformOrigin.vertical = 'center'
      transformOrigin.horizontal = 'left'
      break
    default:
  }

  const theme = useTheme()
  const arrowCSS =
    arrow && position === 'right-center' ?
      {
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: -6,
          width: 12,
          height: 12,
          // bg color does not match menu bg color in dark mode because of menu elevation property, that lightens the background
          bgcolor: theme.palette.mode === 'dark' ? '#3D3D3D' : 'background.menu',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
          boxShadow: '-3px 3px 3px -1px rgba(0,0,0,0.15)'
        }
      }
    : {}

  return (
    <Menu
      elevation={5}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      TransitionComponent={position === 'right-center' ? Zoom : Grow}
      autoFocus={autoFocus}
      sx={{
        '& .MuiPaper-root': {
          overflow: 'visible', // to display menu arrow
          maxHeight: 'none',
          bgcolor: 'background.menu',
          marginTop: position === 'bottom-left' || position === 'bottom-right' ? theme.spacing(0.5) : 0,
          marginLeft:
            position === 'right-center' ?
              arrow ? 1.5
              : theme.spacing(0.5)
            : 0,
          borderRadius: 4,
          '&::before': arrowCSS,
          '& .MuiMenu-list': {
            padding: noPadding ? 0 : '10px',
            '& > li': {
              borderRadius: 2
            }
          },
          ...arrowCSS
        }
      }}
      {...props}
    >
      {children}
    </Menu>
  )
}
