import { getDownloadURL, getStorage, ref, updateMetadata, uploadBytesResumable } from 'firebase/storage'

import app from './app'
import { STORAGE_URL } from './config'

const storage = getStorage(app, STORAGE_URL)

export const getFileURL = file => getDownloadURL(ref(storage, file))
export const updateFileMetadata = (filePath, newMetadata) => updateMetadata(ref(storage, filePath), newMetadata)
export const uploadFile = (location, file, metadata, onStateChange = () => {}) =>
  new Promise((resolve, reject) => {
    uploadBytesResumable(ref(storage, location), file, metadata).on('state_changed', onStateChange, reject, resolve)
  })
