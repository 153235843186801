import { useNavigate, useSearch } from '@tanstack/react-router'
import { useCallback } from 'react'

export default function useURLParams() {
  const navigate = useNavigate()
  const params = useSearch()

  const getParam = useCallback(key => params[key], [params])
  const setParam = useCallback(
    (key, value, hidden = false) => {
      navigate({
        search: search => ({ ...search, [key]: value }),
        mask: {
          search: params => {
            if (hidden) {
              delete params[key]
            } else {
              params[key] = value
            }
            return params
          }
        }
      })
    },
    [navigate]
  )

  return { getParam, setParam }
}
