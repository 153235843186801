import { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Button from '@mui/material/Button'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Stack from '@mui/material/Stack'
import Text from 'components/Text'
import { getCreditsString } from 'utils/string'
import { VIDEO_ACCESS_PERIOD } from 'features/video'

function PriceOffer({ text, price, onClick }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Button color="success" variant="contained" onClick={onClick} size="small" sx={{ minWidth: 120 }}>
        {text}
      </Button>
      <Text disableUserSelection fontWeight={500}>
        {price} {getCreditsString(price)}
      </Text>
    </Stack>
  )
}

export default function PriceOffers({ videoID, prices, onSelect, sx }) {
  const [expanded, setExpanded] = useState(false)
  const handleChange = (event, newState) => setExpanded(newState)
  const handleSelection = period => onSelect && onSelect(period, prices[period])

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      elevation={0}
      disableGutters
      sx={{ backgroundColor: 'background.light', width: 1, ...sx }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`offers-${videoID}-content`}
        id={`offers-${videoID}-header`}
      >
        <Text fontWeight={500}>{expanded ? 'Skrýt' : 'Zobrazit'} nabídky</Text>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          <PriceOffer
            text="Koupit na den"
            price={prices[VIDEO_ACCESS_PERIOD.DAY]}
            onClick={() => handleSelection(VIDEO_ACCESS_PERIOD.DAY)}
          />
          <PriceOffer
            text="Koupit na týden"
            price={prices[VIDEO_ACCESS_PERIOD.WEEK]}
            onClick={() => handleSelection(VIDEO_ACCESS_PERIOD.WEEK)}
          />
          <PriceOffer
            text="Koupit na měsíc"
            price={prices[VIDEO_ACCESS_PERIOD.MONTH]}
            onClick={() => handleSelection(VIDEO_ACCESS_PERIOD.MONTH)}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
