import { memo } from 'react'
import Section from 'components/Section'
import Text from 'components/Text'
import Link from 'components/Link'
import { CONTACT } from 'data/constants'

const Label = ({ name }) => (
  <Text variant="span" color="text.secondary" mr={1}>
    {name}:
  </Text>
)

// TODO: make SECTION_ID global
export default memo(function Impressum() {
  return (
    <Section id="impressum" name="Provozovatel">
      <Text fontSize={16} fontWeight={700} mb={1.75}>
        Ing. Ondřej Kučera, MBA
      </Text>
      <Text gutterBottom>
        <Label name="Sídlo" />
        Maršovská 2239, 68801 Uherský Brod, Česká republika
      </Text>
      <Text gutterBottom>
        <Label name="IČ" />
        21011281
      </Text>
      <Text gutterBottom>
        <Label name="Telefon" />
        {CONTACT.PHONE}
      </Text>
      <Text gutterBottom>
        <Label name="E-mail" />
        <Link navOpts={{ to: 'mailto:' + CONTACT.EMAIL }}>{CONTACT.EMAIL}</Link>
      </Text>
    </Section>
  )
})
