import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import Portal from '@mui/material/Portal'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Backdrop from 'components/Backdrop'
import { AuthenticationBackdrop, useUser } from 'features/authentication'
import Notification from 'components/Notification'
import InsufficientFunds from './InsufficientFunds'
import Text from 'components/Text'
import { getCreditsString } from 'utils/string'
import { getPeriodString } from '../utils/string'
import { usePosterURL } from 'features/video'
import { buyVideo } from 'services/firebase'
import useCacheInvalidation from 'hooks/useCacheInvalidation'

export default function PurchaseBackdrop({ video, selectedPeriod, open, onClose, onSuccess }) {
  const [loading, setLoading] = useState(false)
  const [insufficientFunds, setInsufficientFunds] = useState(true)
  const [openAuth, setOpenAuth] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const posterURL = usePosterURL(video.id)

  const handleAuthOpen = () => setOpenAuth(true)
  const handleAuthClose = () => setOpenAuth(false)

  const user = useUser()
  const invalidateQuery = useCacheInvalidation()

  const handleSuccess = () => {
    invalidateQuery([user.data.id, 'balance-history'])
    invalidateQuery('video-stats')
    onClose && onClose()
    onSuccess && onSuccess()
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (user.isLoggedIn && video.price[selectedPeriod] < user.data.wallet) {
      setLoading(true)
      buyVideo({ videoID: video.id, period: selectedPeriod })
        .then(handleSuccess)
        .catch(() => setOpenAlert(true))
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    if (user.isLoggedIn && !loading) {
      setInsufficientFunds(video.price[selectedPeriod] > user.data.wallet)
    }
  }, [video.price, selectedPeriod, user, loading])

  return (
    <Backdrop open={open} onClose={onClose} title="Koupit přístup k videu">
      <Notification open={openAlert} onClose={() => setOpenAlert(false)}>
        Chyba! Něco se pokazilo.
      </Notification>
      {user.isLoggedIn ?
        <>
          {posterURL.isLoading && <Skeleton variant="rectangle" height="100%" />}
          {posterURL.isSuccess && (
            <Box
              overflow="hidden"
              borderRadius={4}
              mb={2}
              sx={{
                backgroundColor: 'black',
                '& img': { opacity: 0.5, display: 'block' }
              }}
            >
              <img src={posterURL.data} alt={video.name} />
            </Box>
          )}
          <Box px={3}>
            <Text color="text.primary" fontSize={16} fontWeight={500}>
              {video.group}: {video.name}
            </Text>
            <Text color="text.secondary" fontSize={12} align="justify">
              {video.description}
            </Text>
          </Box>
          <Stack mt={4} direction="row" justifyContent="space-between">
            <Text variant="span" color="text.secondary" fontSize={16} disableUserSelection>
              Doba přístupu:
            </Text>
            <Text variant="span" fontWeight={500} fontSize={16} disableUserSelection>
              {getPeriodString(selectedPeriod)}
            </Text>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Text variant="span" color="text.secondary" fontSize={16} disableUserSelection>
              Cena:
            </Text>
            <Text
              variant="span"
              fontWeight={500}
              fontSize={16}
              disableUserSelection
              color={insufficientFunds ? 'error' : 'text.primary'}
            >
              {video.price[selectedPeriod]} {getCreditsString(video.price[selectedPeriod])}
            </Text>
          </Stack>
          {!insufficientFunds && (
            <Text align="right" color="text.secondary">
              Váš aktuální zůstatek je {user.data.wallet} {getCreditsString(user.data.wallet)}
            </Text>
          )}
          {insufficientFunds ?
            <InsufficientFunds balance={user.data.wallet} mt={4} />
          : <FormControl fullWidth>
              <Button
                sx={{ mt: 5, borderRadius: 3, fontSize: 16, py: 1.5, textTransform: 'none' }}
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                disabled={loading || insufficientFunds}
              >
                Koupit {loading && <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />}
              </Button>
            </FormControl>
          }
        </>
      : <>
          <Text variant="h6" align="center">
            Pro nákup videí je potřeba se nejprve přihlásit!
          </Text>
          <Button
            fullWidth
            sx={{ mt: 5, borderRadius: 3, fontSize: 16, py: 1.5, textTransform: 'none' }}
            variant="contained"
            onClick={handleAuthOpen}
            disabled={openAuth}
          >
            Přihlásit se {openAuth && <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />}
          </Button>
          <Portal container={document.getElementById('root')}>
            <AuthenticationBackdrop open={openAuth} onClose={handleAuthClose} />
          </Portal>
        </>
      }
    </Backdrop>
  )
}
