import { useMemo } from 'react'
import { styled } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import InfoIcon from '@mui/icons-material/Info'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import Subsection from 'components/Subsection'
import Text from 'components/Text'
import useVideoStats from '../hooks/useVideoStats'
import { getCreditsString } from 'utils/string'
import { VIDEO_CATEGORY } from 'features/video'
import { naturalSort } from 'utils/sort'

const HeaderCell = styled(props => (
  <TableCell align="center" sx={{ fontWeight: 800, userSelect: 'none' }} {...props} />
))({})
const BodyCell = styled(props => <TableCell align="center" sx={{ userSelect: 'none' }} {...props} />)({})

export default function CategoryStats(props) {
  const videoStats = useVideoStats()

  const categoryStats = useMemo(() => {
    if (videoStats.isSuccess) {
      const categories = {}
      Object.values(VIDEO_CATEGORY).forEach(v => (categories[v] = { category: v, profit: 0, purchases: 0 }))
      videoStats.data.forEach(video => {
        if (video.category in categories) {
          const cs = categories[video.category]
          cs.profit += video.profit
          Object.values(video.purchases).forEach(p => (cs.purchases += p))
        }
      })
      return naturalSort(Object.values(categories), 'profit', true)
    } else {
      return []
    }
  }, [videoStats])

  return (
    <Subsection name="Statistiky kategorií" {...props}>
      {videoStats.isError && (
        <Stack direction="row" alignItems="center" color="error.main">
          <CancelIcon sx={{ fontSize: 20, mr: 0.5 }} />
          <Text fontWeight={600}>Chyba! Nepodařilo se načíst statistiky.</Text>
        </Stack>
      )}
      {videoStats.isLoading && <Skeleton variant="rounded" width={350} height={125} />}
      {videoStats.isSuccess &&
        (videoStats.data.length === 0 ?
          <Stack direction="row" alignItems="center" color="warning.main">
            <InfoIcon sx={{ fontSize: 20, mr: 0.5 }} />
            <Text>Zatím nebyla nahrána žádná videa.</Text>
          </Stack>
        : <Paper elevation={4} sx={{ width: 'max-content', maxWidth: '100%', mt: 1, overflow: 'auto' }}>
            <TableContainer>
              <Table size="small" sx={{ width: 'max-content' }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'background.table.header' }}>
                    <HeaderCell align="left">Kategorie</HeaderCell>
                    <HeaderCell>Profit</HeaderCell>
                    <HeaderCell>Celkem nákupů</HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryStats.map(cs => (
                    <TableRow key={cs.category} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <BodyCell align="left">
                        <Text fontWeight={600}>{cs.category}</Text>
                      </BodyCell>
                      <BodyCell>
                        <Text fontWeight={500} fontFamily="Roboto" color={cs.profit ? 'success.main' : 'error.main'}>
                          {cs.profit} {getCreditsString(cs.profit)}
                        </Text>
                      </BodyCell>
                      <BodyCell>{cs.purchases}</BodyCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>)}
    </Subsection>
  )
}
