import { memo, useState } from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { DropdownMenu, DropdownMenuItem } from 'components/dropdown-menu'
import NavLink from 'components/NavLink'
import { MAIN_PAGE_SECTION_ID } from 'pages'

// icons
import MenuIcon from '@mui/icons-material/Menu'

export default memo(function SmallScreenMenu(props) {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const isOpen = Boolean(menuAnchor)
  const menuID = 'menu-small-screen'
  const handleMenuClose = () => setMenuAnchor(null)
  const handleMenuOpen = event => setMenuAnchor(event.target)

  const { palette } = useTheme()
  const menuItemColor = palette.mode === 'dark' ? 'white' : 'black'

  return (
    <Box {...props}>
      <IconButton
        size="large"
        aria-label="Small screen menu"
        aria-controls="menu-small-screen"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <DropdownMenu id={menuID} anchorEl={menuAnchor} open={isOpen} onClose={handleMenuClose} position="bottom-right">
        <DropdownMenuItem onClick={handleMenuClose}>
          <NavLink color={menuItemColor} hash={MAIN_PAGE_SECTION_ID.HOME}>
            Domů
          </NavLink>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleMenuClose}>
          <NavLink color={menuItemColor} hash={MAIN_PAGE_SECTION_ID.PRICING}>
            Ceník
          </NavLink>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleMenuClose}>
          <NavLink color={menuItemColor} hash={MAIN_PAGE_SECTION_ID.CONTACT}>
            Kontakt
          </NavLink>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleMenuClose}>
          <NavLink color={menuItemColor} to="video-library">
            Videotéka
          </NavLink>
        </DropdownMenuItem>
      </DropdownMenu>
    </Box>
  )
})
