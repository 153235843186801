import { forwardRef, memo } from 'react'
import { styled } from '@mui/material'
import { Link as TanstackLink } from '@tanstack/react-router'
import clsx from 'clsx'

const StyledLink = styled(TanstackLink)(({ theme }) => ({
  // color: theme.palette.mode === 'light' ? theme.palette.link.light : theme.palette.warning.main,
  '&:hover': {
    // color: theme.palette.mode === 'light' ? theme.palette.link.main : theme.palette.warning.dark
  },
  '&.color-text': {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.text.primary
    }
  },
  '&.color-warning': {
    color: theme.palette.warning.main,
    '&:hover': {
      color: theme.palette.warning.dark
    }
  },
  cursor: 'pointer',
  fontSize: 'inherit',
  fontWeight: 500,
  textDecoration: 'none',
  transition: `color ${theme.transitions.duration.short}ms`
}))

const Link = forwardRef(
  (
    {
      navOpts = null,
      className = null,
      target = null,
      onClick = null,
      warningColor = false,
      textColor = false,
      preventDefault = false,
      style,
      sx,
      ...props
    },
    ref
  ) => {
    const handleClick = e => {
      preventDefault && e.preventDefault()
      onClick && onClick(e)
    }

    return (
      <StyledLink
        className={clsx(className, {
          'color-warning': warningColor,
          'color-text': textColor
        })}
        ref={ref}
        onClick={handleClick}
        target={target}
        disabled={navOpts === null}
        style={{ ...style, ...sx }}
        {...(navOpts === null ? {} : navOpts)}
        {...props}
      />
    )
  }
)

export default memo(Link)
