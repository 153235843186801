import Skeleton from '@mui/material/Skeleton'
import { Navigate, createFileRoute } from '@tanstack/react-router'
import { useUser } from 'features/authentication'
import { Administration } from 'pages'

function AdministrationWrapper() {
  const user = useUser()
  return (
    user.isLoading ? <Skeleton variant="rectangular" height="100%" />
    : user.isLoggedIn && (user.data.isAdmin || user.data.isDeveloper) ? <Administration />
    : <Navigate to="/" />
  )
}

export const Route = createFileRoute('/administration')({
  component: AdministrationWrapper
})
