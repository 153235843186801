export const SECTION_ID = {
  HOME: 'home',
  ABOUT_US: 'about-us',
  CONTACT: 'contact',
  CONTACT_FORM: 'contact-form',
  FAQ: 'faq',
  INTRODUCTION: 'introduction',
  PRICING: 'pricing',
  TIPS: 'tips',
  TUTORING: 'tutoring'
}
