import { useRef, useState } from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import SocialButton, { SOCIAL_BUTTON_TYPE } from './SocialButton'
import Text from 'components/Text'
import { BACKDROP_CONTENT_TYPE } from '../data/constants'
import { useUser } from '../hooks/useUser'
import { validateEmail } from 'utils/validation'
import PolicyConfirmationLabel from 'components/PolicyConfirmationLabel'

export default function Register({ onClose, onContentChange, ...props }) {
  const user = useUser()

  const nameRef = useRef(null)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const passwordConfirmRef = useRef(null)

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [emailTaken, setEmailTaken] = useState(false)
  const [passwordLengthError, setPasswordLengthError] = useState(false)
  const [passwordMismatch, setPasswordMismatch] = useState(false)
  const [unknownError, setUnknownError] = useState(false)

  const checkPasswords = () => {
    if (passwordRef.current.value.length < 6) {
      setPasswordLengthError(true)
      return false
    } else if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      setPasswordMismatch(true)
      return false
    } else {
      return true
    }
  }

  const checkName = () => {
    if (nameRef.current.value === '') {
      setNameError(true)
      return false
    } else {
      return true
    }
  }

  const checkEmail = () => {
    if (validateEmail(emailRef.current.value)) {
      return true
    } else {
      setEmailError(true)
      return false
    }
  }

  const handleSignInClick = event => {
    event.preventDefault()
    onContentChange(BACKDROP_CONTENT_TYPE.SIGNIN)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (!submitted) {
      setSubmitted(true)
    }
    if (emailTaken) {
      setEmailTaken(false)
    }

    const validName = checkName()
    const validEmail = checkEmail()
    const validPasswords = checkPasswords()
    if (validName && validEmail && validPasswords) {
      setLoading(true)
      user
        .register(nameRef.current.value, emailRef.current.value, passwordRef.current.value)
        .then(() => onContentChange(BACKDROP_CONTENT_TYPE.SIGNIN))
        .catch(error => {
          if (error.code === 'auth/email-already-in-use') {
            setEmailTaken(true)
          } else {
            setUnknownError(true)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleNameChange = event => {
    if (submitted) {
      setNameError(nameRef.current.value === '')
    }
  }

  const handleEmailChange = event => {
    if (submitted) {
      setEmailError(!validateEmail(emailRef.current.value))
      setEmailTaken(false)
    }
  }

  const handlePasswordChange = event => {
    if (submitted) {
      setPasswordLengthError(passwordRef.current.value.length < 6)
      setPasswordMismatch(passwordRef.current.value !== passwordConfirmRef.current.value)
    }
  }

  const handlePasswordConfirmationChange = evet => {
    if (submitted) {
      setPasswordMismatch(passwordRef.current.value !== passwordConfirmRef.current.value)
    }
  }

  const handleGoogleClick = async event => {
    event.preventDefault()

    try {
      setLoading(true)
      await user.loginWithGoogle()
      setLoading(false)
      onClose && onClose()
    } catch {
      setLoading(false)
    }
  }

  const handleFacebookClick = async event => {
    event.preventDefault()

    try {
      setLoading(true)
      await user.loginWithFacebook()
      setLoading(false)
      onClose && onClose()
    } catch {
      setLoading(false)
    }
  }

  return (
    <>
      <Snackbar
        open={unknownError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setUnknownError(false)}
      >
        <Alert severity="error" variant="filled" onClose={() => setUnknownError(false)}>
          Vyskytla se neznámá chyba.
        </Alert>
      </Snackbar>
      <FormControl component="form" fullWidth>
        <TextField
          inputRef={nameRef}
          error={nameError}
          helperText={nameError ? 'Zadejte celé své jméno.' : ''}
          type="name"
          name="name"
          label="Jméno a příjmení"
          required
          autoFocus
          autoComplete="on"
          sx={{ mb: 2, '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handleNameChange}
        />
        <TextField
          inputRef={emailRef}
          error={emailError || emailTaken}
          helperText={
            emailError ? 'Neplatná e-mailová adresa.'
            : emailTaken ?
              'Zadaná e-mailová adresa je již použita.'
            : ''
          }
          type="email"
          name="email"
          label="E-mail"
          required
          sx={{ mb: 2, '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handleEmailChange}
        />
        <TextField
          inputRef={passwordRef}
          error={passwordLengthError}
          helperText={passwordLengthError ? 'Heslo musí obsahovat alespoň 6 znaků.' : ''}
          type="password"
          name="password"
          label="Heslo"
          required
          sx={{ mb: 2, '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handlePasswordChange}
        />
        <TextField
          inputRef={passwordConfirmRef}
          error={passwordMismatch}
          helperText={passwordMismatch ? 'Hesla se neshodují.' : ''}
          type="password"
          name="password-confirm"
          label="Potvrdit heslo"
          required
          sx={{ '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handlePasswordConfirmationChange}
        />
        <PolicyConfirmationLabel onLinkClick={onClose} prefix="Registrací" sx={{ mt: 3, mb: 1, textAlign: 'center' }} />
        <Button
          sx={{ borderRadius: 3, fontSize: 16, py: 1.5, textTransform: 'none' }}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          Registrovat {loading && <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />}
        </Button>
      </FormControl>
      <Stack my={4} direction="row" alignItems="center" justifyContent="space-between">
        <Text variant="span" sx={{ height: '1px', backgroundColor: 'text.secondary', display: 'inline-block' }} />
        <Text color="text.secondary" fontSize={11} sx={{ px: 2, textAlign: 'center', userSelect: 'none' }}>
          nebo se registrujte pomocí
        </Text>
        <Text variant="span" sx={{ height: '1px', backgroundColor: 'text.secondary', display: 'inline-block' }} />
      </Stack>
      <Stack direction="row" justifyContent="space-evenly">
        <SocialButton type={SOCIAL_BUTTON_TYPE.GOOGLE} onClick={handleGoogleClick} />
        <SocialButton type={SOCIAL_BUTTON_TYPE.FACEBOOK} onClick={handleFacebookClick} />
      </Stack>
      <Text color="text.secondary" fontSize={11} sx={{ mt: 2, textAlign: 'center' }} disableUserSelection>
        Již máte účet?{' '}
        <a href="/login" onClick={handleSignInClick}>
          Přihlaste se.
        </a>
      </Text>
    </>
  )
}
