import { useRef, useState } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { validateEmail } from 'utils/validation'
import { FORM_INPUT_NAME, sendEmail } from 'services/email'
import Notification from 'components/Notification'

export default function ContactForm(props) {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [nameError, setNameError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [notificationType, setNotificationType] = useState('success')
  const [openNotification, setOpenNotification] = useState(false)

  const formRef = useRef(null)
  const nameRef = useRef(null)
  const emailRef = useRef(null)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = async event => {
    event.preventDefault()
    if (!submitted) {
      setSubmitted(true)
    }

    let valid = true
    // name validation
    if (nameRef.current.value.length === 0) {
      setNameError('Zadejte Vaše jméno.')
      valid = false
    } else {
      setNameError(null)
    }
    // email validation
    if (emailRef.current.value.length === 0) {
      setEmailError('Zadejte Váš e-mail.')
      valid = false
    }
    if (!validateEmail(emailRef.current.value)) {
      setEmailError('Neplatná e-mailová adresa.')
      valid = false
    } else {
      setEmailError(null)
    }

    if (valid) {
      setLoading(true)
      sendEmail(formRef.current)
        .then(() => {
          setNotificationType('success')
          setName('')
          setEmail('')
          setMessage('')
        })
        .catch(() => setNotificationType('error'))
        .finally(() => {
          setLoading(false)
          setOpenNotification(true)
        })
    }
  }

  const handleNameChange = event => {
    if (submitted) {
      setNameError(nameRef.current.value.length === 0 ? 'Zadejte Vaše jméno.' : null)
    }
    setName(event.target.value)
  }

  const handleEmailChange = event => {
    if (submitted) {
      setEmailError(validateEmail(emailRef.current.value) ? null : 'Neplatná e-mailová adresa.')
    }
    setEmail(event.target.value)
  }

  const handleMessageChange = event => {
    setMessage(event.target.value)
  }

  return (
    <>
      <Notification
        severity={notificationType}
        open={openNotification}
        hideAfter={2000}
        onClose={() => setOpenNotification(false)}
      >
        {notificationType === 'error' ? 'Dotaz se nepodařilo odeslat.' : 'Dotaz byl úspěšně odeslán!'}
      </Notification>
      <FormControl ref={formRef} component="form" fullWidth {...props}>
        <TextField
          inputRef={nameRef}
          value={name}
          error={nameError != null}
          helperText={nameError}
          name={FORM_INPUT_NAME.NAME}
          label="Jméno"
          required
          autoComplete="on"
          sx={{ mb: 2, '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handleNameChange}
        />
        <TextField
          inputRef={emailRef}
          value={email}
          error={emailError != null}
          helperText={emailError}
          type="email"
          name={FORM_INPUT_NAME.EMAIL}
          label="E-mailová adresa"
          required
          autoComplete="on"
          sx={{ mb: 2, '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handleEmailChange}
        />
        <TextField
          value={message}
          type="text"
          name={FORM_INPUT_NAME.MESSAGE}
          label="Zpráva"
          multiline
          rows={5}
          sx={{ mb: 3, '& .MuiInputBase-root': { borderRadius: 3 } }}
          onChange={handleMessageChange}
        />
        <Button type="submit" variant="contained" disabled={loading} onClick={handleSubmit}>
          Odeslat Dotaz {loading && <CircularProgress color="inherit" size={16} sx={{ ml: 1 }} />}
        </Button>
      </FormControl>
    </>
  )
}
