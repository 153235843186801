import { memo } from 'react'
import Stack from '@mui/material/Stack'
import NavLink from 'components/NavLink'

const StyledNavLink = ({ to, hash, ...props }) => (
  <NavLink to={to} hash={hash} sx={{ fontWeight: 400, fontSize: 12, textWrap: 'nowrap' }} {...props} />
)

export default memo(function Footer() {
  return (
    <Stack
      width={1}
      alignItems="center"
      justifyContent="center"
      mx="auto"
      py={0.5}
      spacing={1}
      sx={{ userSelect: 'none' }}
    >
      <Stack direction="row" spacing={2} flexWrap="wrap" justifyContent="center">
        <StyledNavLink to="/policies/impressum">Provozovatel</StyledNavLink>
        <StyledNavLink to="/policies/terms-and-conditions">Obchodní podmínky</StyledNavLink>
        <StyledNavLink to="/policies/privacy-notice">Ochrana osobních údajů</StyledNavLink>
      </Stack>
      <StyledNavLink to="/policies/impressum">&copy; 2024, Ing. Ondřej Kučera, MBA</StyledNavLink>
    </Stack>
  )
})
