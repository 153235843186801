import useCacheData from 'hooks/useCacheData'
import { getFileURL } from 'services/firebase'

async function fetchVideoURL(videoName) {
  const data = await getFileURL(`videos/${videoName}`)
  return data
}

export default function useVideoURL(videoName) {
  return useCacheData(['video', videoName], () => fetchVideoURL(videoName))
}
