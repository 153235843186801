export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDQCzl4kCChLIrvbntoZdValWxCXTN1Au8',
  authDomain: 'kucci-tutoring.firebaseapp.com',
  projectId: 'kucci-tutoring',
  storageBucket: 'kucci-tutoring.appspot.com',
  messagingSenderId: '644661218648',
  appId: '1:644661218648:web:502a8d503014fe73a5a92d'
}

export const STORAGE_URL = 'gs://kucci-tutoring.appspot.com'
