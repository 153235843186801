import Stack from '@mui/material/Stack'
import Link from 'components/Link'
import Text from 'components/Text'

export default function SocialMediaLink({ Icon, iconColor, href, children }) {
  return (
    <Link navOpts={{ to: href }} target="_blank">
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Icon sx={{ color: iconColor, fontSize: { xs: 18, md: 22 } }} />
        <Text color="text.primary" fontSize={{ xs: 12, md: 14 }}>
          {children}
        </Text>
      </Stack>
    </Link>
  )
}
