import { styled } from '@mui/material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import CancelIcon from '@mui/icons-material/Cancel'
import InfoIcon from '@mui/icons-material/Info'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import DateLabel from 'components/DateLabel'
import Section from 'components/Section'
import Text from 'components/Text'
import useBalanceHistory from '../hooks/useBalanceHistory'
import { getCreditsString } from 'utils/string'

const HeaderCell = styled(props => (
  <TableCell align="center" sx={{ fontWeight: 800, userSelect: 'none' }} {...props} />
))({})
const BodyCell = styled(props => <TableCell align="center" sx={{ userSelect: 'none' }} {...props} />)({})

export default function BalanceHistory() {
  const balanceHistory = useBalanceHistory()

  return (
    <Section id="balance-history" name="Historie transakcí" sx={{ textAlign: 'justify', height: 1 }}>
      {balanceHistory.isError && (
        <Stack direction="row">
          <CancelIcon color="error" sx={{ fontSize: 30, mr: 1 }} />
          <Text variant="h6" color="error" fontWeight={600}>
            Chyba! Nepodařilo se načíst historii nákupů.
          </Text>
        </Stack>
      )}
      {balanceHistory.isLoading && <Skeleton variant="rounded" width="100%" sx={{ height: { xs: 320, md: 640 } }} />}
      {balanceHistory.isSuccess &&
        (balanceHistory.data.length === 0 ?
          <Stack direction="row" alignItems="center" color="warning.main">
            <InfoIcon sx={{ fontSize: 16, mr: 0.5 }} />
            <Text>Zatím nebyly provedeny žádné transakce.</Text>
          </Stack>
        : <Paper elevation={4} sx={{ width: 'max-content', maxWidth: '100%', mt: 1, overflow: 'auto' }}>
            <TableContainer>
              <Table size="small" sx={{ width: 'max-content' }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'background.table.header' }}>
                    <HeaderCell>Datum a čas</HeaderCell>
                    <HeaderCell>Typ</HeaderCell>
                    <HeaderCell>Množství</HeaderCell>
                    <HeaderCell>
                      <Stack direction="row" alignItems="center">
                        <AccountBalanceIcon sx={{ fontSize: 13, mr: 0.2 }} />
                        Zůstatek
                      </Stack>
                    </HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {balanceHistory.data.map(record => {
                    const isAdding = record.type === 'top-up'
                    return (
                      <TableRow key={record.timestamp} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <BodyCell align="center" sx={{ fontFamily: 'Roboto' }}>
                          <DateLabel timestamp={record.timestamp} withTime />
                        </BodyCell>
                        <BodyCell
                          align="center"
                          sx={{ fontWeight: 500, color: isAdding ? 'success.main' : 'error.main' }}
                        >
                          {isAdding ? 'dobití' : 'útrata'}
                        </BodyCell>
                        <BodyCell
                          align="right"
                          sx={{
                            fontFamily: 'Roboto',
                            fontWeight: 500,
                            color: isAdding ? 'success.main' : 'error.main'
                          }}
                        >
                          {isAdding ? '+' : '-'}
                          {record.amount} {getCreditsString(record.balance)}
                        </BodyCell>
                        <BodyCell align="right" sx={{ fontFamily: 'Roboto', fontWeight: 600 }}>
                          {record.balance} {getCreditsString(record.balance)}
                        </BodyCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>)}
    </Section>
  )
}
