import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import EditIcon from '@mui/icons-material/Edit'
import Portal from '@mui/material/Portal'

import { getCzechDateFormat } from 'utils/date'
import { Video, VideoEdit } from 'features/video'
import { useUser } from 'features/authentication'
import AdminDetails from './AdminDetails'
import Notification from 'components/Notification'
import PriceOffers from './PriceOffers'
import PurchaseBackdrop from './PurchaseBackdrop'
import Text from 'components/Text'
import VideoBanner from './VideoBanner'

export default function VideoCard({ video, hasAccess = false, availableUntil = null, ...props }) {
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState(null)
  const [showPurchaseSuccessNotification, setShowPurchaseSuccessNotification] = useState(false)
  const handleBackdropClose = () => setOpenBackdrop(false)
  const handleBackdropOpen = () => setOpenBackdrop(true)

  const handleOfferSelection = (period, price) => {
    setSelectedPeriod(period)
    handleBackdropOpen()
  }

  // video editing
  const user = useUser()
  const isAdminOrDeveloper = user.isLoggedIn && (user.data?.isAdmin || user.data?.isDeveloper)
  const [openVideoEdit, setOpenVideoEdit] = useState(false)
  const handleEditVideoClick = () => setOpenVideoEdit(true)
  const handleVideoEditSuccess = () => setOpenVideoEdit(false)

  return (
    <Card
      sx={{
        minWidth: 250,
        maxWidth: 264,
        mx: { xs: 0, md: 1 },
        mb: { xs: 2, md: 0 },
        backgroundColor: 'inherit',
        display: 'flex',
        flexDirection: 'column'
      }}
      elevation={0}
    >
      <Notification
        severity="success"
        hideAfter={2000}
        open={showPurchaseSuccessNotification}
        onClose={() => setShowPurchaseSuccessNotification(false)}
      >
        Video bylo úspěšně zakoupeno.
      </Notification>
      <VideoEdit video={openVideoEdit ? video : null} onSuccess={handleVideoEditSuccess} />
      {isAdminOrDeveloper && (
        <Text color="text.secondary" fontSize={12} sx={{ mb: 0.5 }}>
          Pořadí ve skupině:{' '}
          <Text variant="span" fontWeight={600}>
            {video.groupOrder}
          </Text>
        </Text>
      )}
      <Box height={148} overflow="hidden" borderRadius={3}>
        {hasAccess ?
          <CardMedia component={Video} videoName={video.id} posterName={video.id} />
        : <CardMedia component={VideoBanner} poster={video.id} videoName={video.name} price={video.price} />}
      </Box>
      <CardContent>
        <Text fontSize={16} fontWeight={500} disableUserSelection sx={{ mb: 0.5 }}>
          {video.group}: {video.name}
        </Text>
        <Text color="text.secondary" fontSize={12} disableUserSelection>
          {video.description}
        </Text>
        {hasAccess && !isAdminOrDeveloper && (
          <Text fontSize={10} color="success.main" mt={1} disableUserSelection>
            {video.paid ?
              <>
                Video je přístupné do{' '}
                <Text variant="span" fontWeight={500}>
                  {getCzechDateFormat(availableUntil)}
                </Text>
              </>
            : 'Toto video je zdarma'}
          </Text>
        )}
      </CardContent>
      {(!hasAccess || isAdminOrDeveloper) && (
        <CardActions sx={{ p: 0, pb: 1, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
          {!hasAccess && (
            <>
              <PriceOffers prices={video.price} videoID={video.id} onSelect={handleOfferSelection} />
              <Portal container={document.getElementById('root')}>
                <PurchaseBackdrop
                  video={video}
                  selectedPeriod={selectedPeriod}
                  open={openBackdrop}
                  onClose={handleBackdropClose}
                  onSuccess={() => setShowPurchaseSuccessNotification(true)}
                />
              </Portal>
            </>
          )}
          {isAdminOrDeveloper && (
            <>
              <AdminDetails video={video} width="80%" />
              <Button variant="outlined" size="small" startIcon={<EditIcon />} onClick={handleEditVideoClick}>
                Upravit video
              </Button>
            </>
          )}
        </CardActions>
      )}
    </Card>
  )
}
