import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Stack from '@mui/material/Stack'
import { getCzechDateFormat } from 'utils/date'
import Text from './Text'

export default function DateLabel({ timestamp, withTime = false, ...props }) {
  return (
    <Stack direction="row" alignItems="center" {...props}>
      <CalendarMonthIcon sx={{ fontSize: 16, mr: 0.4 }} />
      <Text variant="span" fontFamily="Roboto">
        {getCzechDateFormat(timestamp, withTime)}
      </Text>
    </Stack>
  )
}
