import { memo } from 'react'
import Section from 'components/Section'
import Text from 'components/Text'

// TODO: make SECTION_ID global
export default memo(function PrivacyNotice() {
  return (
    <Section id="privacy-notice" name="Ochrana osobních údajů" sx={{ textAlign: 'justify' }}>
      <Text>
        Svou informační povinnost vůči kupujícímu ve smyslu čl. 13 Nařízení Evropského parlamentu a Rady 2016/679 o
        ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení
        směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (dále jen „nařízení GDPR“) související se
        zpracováním osobních údajů kupujícího pro účely plnění kupní smlouvy, pro účely jednání o této smlouvě a pro
        účely plnění veřejnoprávních povinností prodávajícího plní prodávající prostřednictvím zvláštního dokumentu.
      </Text>
    </Section>
  )
})
