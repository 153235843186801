import { logComponentRendering } from 'utils/debug'
import { createContext, memo, useMemo } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import useLocalStorage from 'hooks/useLocalStorage'
import { createCustomTheme } from 'data/themeConfig'

export const ColorModeContext = createContext({ toggleTheme: () => {} })

export default memo(function ThemeContextProvider({ children }) {
  logComponentRendering(ThemeContextProvider)
  // System preference theme
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useLocalStorage('theme', prefersDark ? 'dark' : 'light')

  const toggleTheme = () => setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'))
  const theme = useMemo(() => createCustomTheme(mode), [mode])

  return (
    <ColorModeContext.Provider value={{ toggleTheme }}>
      <ThemeProvider theme={theme}>
        {/* Adding <CssBaseline /> inside of the <ThemeProvider> component will also enable dark mode for the app's background. */}
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
})
