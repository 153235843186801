import { useState } from 'react'
import TextField from '@mui/material/TextField'

export default function OrderInput({ value, error, onChange, name, label, required, sx }) {
  const [emptyString, setEmptyString] = useState(false)
  const handleChange = event => {
    const order = String(event.target.value).trim()
    if (order === '' || (!isNaN(order) && !isNaN(parseFloat(order)))) {
      setEmptyString(order === '')
      onChange && onChange(order)
    }
  }

  return (
    <TextField
      value={value}
      error={error || emptyString}
      helperText={error || emptyString ? 'Ondro, zadej pořadí!' : null}
      type="text"
      name={name}
      label={label}
      sx={{ minWidth: 100, '& .MuiInputBase-root': { borderRadius: 3, '& > input': { textAlign: 'right' } }, ...sx }}
      onChange={handleChange}
      required={required}
    />
  )
}
