import Box from '@mui/material/Box'
import Text from 'components/Text'
import NavLink from 'components/NavLink'
import { CONTRACT_WITHDRAWAL_ID } from 'pages/terms-and-conditions'

const StyledNavLink = ({ to, hash = null, ...props }) => (
  <NavLink
    to={to}
    hash={hash}
    color="inherit"
    sx={{ fontWeight: 400, fontSize: 12, textDecoration: 'underline' }}
    {...props}
  />
)

export default function PolicyConfirmationLabel({ prefix, onLinkClick = null, contractWithdrawal = false, ...props }) {
  return (
    <Box maxWidth={450} {...props}>
      <Text color="text.secondary" fontSize={12}>
        {prefix} souhlasíte s&nbsp;
        <StyledNavLink to="/policies/terms-and-conditions" onClick={onLinkClick}>
          obchodními podmínkami
        </StyledNavLink>
        {contractWithdrawal ? ', ' : <> a&nbsp;</>}
        <StyledNavLink to="/policies/privacy-notice" onClick={onLinkClick}>
          zásadami ochrany osobních údajů
        </StyledNavLink>
        {contractWithdrawal && (
          <>
            {' '}
            a&nbsp;
            <StyledNavLink to="/policies/terms-and-conditions" hash={CONTRACT_WITHDRAWAL_ID} onClick={onLinkClick}>
              podmínkami pro&nbsp;odstoupení od&nbsp;smlouvy
            </StyledNavLink>
          </>
        )}
        .
      </Text>
    </Box>
  )
}
