import { useEffect } from 'react'
import useCacheData from 'hooks/useCacheData'
import { getCollection, onCollectionChange } from 'services/firebase'
import { naturalSort } from 'utils/sort'
import useCacheInvalidation from 'hooks/useCacheInvalidation'

async function fetchOffers() {
  const data = await getCollection('offers')
  return data != null ?
      naturalSort(
        data.docs.map(item => ({ id: item.id, ...item.data() })),
        'price'
      )
    : null
}

export default function useOffers() {
  const invalidateQuery = useCacheInvalidation()

  useEffect(() => {
    const unsubscribe = onCollectionChange('/offers', () => invalidateQuery('offers'))
    return () => unsubscribe('/offers')
  }, [invalidateQuery])

  return useCacheData('offers', fetchOffers)
}
